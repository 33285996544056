import {
	Node,
	NodeAction,
	NODE_ACTION
} from "components/management/node/types";

const initialState: Node[] = [];

export function nodeListReducer(
	state: Node[] = initialState,
	action: NodeAction
): Node[] {
	switch (action.type) {
		case NODE_ACTION.LIST_FETCH_SUCCEDED:
			return action.payload.nodeList;
		default:
			return state;
	}
}
