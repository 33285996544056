import { hostListFetchSucceded } from "components/management/host/actions";
import { HOST_ACTION } from "components/management/host/types";
import HostsApi from "modules/api/HostsApi";
import { call, put, takeLatest } from "redux-saga/effects";

function* fetchHostList() {
	// console.log("fetchHostList saga");

	try {
		const response = yield call(HostsApi.fetchList);
		// console.log("saga host list fetched", response);

		yield put(hostListFetchSucceded(response.data));
	} catch (e) {
		console.error(e);
	}
}

function* HostSideEffects() {
	yield takeLatest(HOST_ACTION.LIST_FETCH_REQUESTED, fetchHostList);
}

export default HostSideEffects;
