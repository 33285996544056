import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) =>
	createStyles({
		flexContainer: {
			display: "flex",
			alignItems: "center",
			boxSizing: "border-box"
		},
		tableRow: {
			cursor: "pointer"
		},
		tableRowHover: {
			"&:hover": {
				backgroundColor: theme.palette.grey[200]
			}
		},
		tableCell: {
			flex: 1,
			textOverflow: "ellipsis",
			whiteSpace: "nowrap",
			overflow: "hidden",
			fontSize: "small"
		},
		noClick: {
			cursor: "initial"
		}
	});