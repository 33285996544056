import { call, put, takeEvery } from "redux-saga/effects";
import {
	NODE_CREATE_WIZARD_ACTION,
	NodeCreateWizardDeleteHostJobStartedAction,
	NodeCreateWizardDeleteHostRequestedAction,
	NodeCreateWizardDeployHostJobStartedAction,
	NodeCreateWizardDeployHostRequestedAction,
	NodeCreateWizardInstallNodeJobStartedAction,
	NodeCreateWizardInstallNodeRequestedAction
} from "components/management/node/nodeCreateWizard/types";
import HostsApi from "modules/api/HostsApi";
import {
	nodeCreateWizardDeleteHostFailed,
	nodeCreateWizardDeleteHostJobStarted,
	nodeCreateWizardDeleteHostSucceded,
	nodeCreateWizardDeployHostFailed,
	nodeCreateWizardDeployHostJobStarted,
	nodeCreateWizardDeployHostSucceded,
	nodeCreateWizardInstallNodeFailed,
	nodeCreateWizardInstallNodeJobStarted,
	nodeCreateWizardInstallNodeRequested,
	nodeCreateWizardInstallNodeSucceded
} from "components/management/node/nodeCreateWizard/actions";
import NodesApi from "modules/api/NodesApi";
import JobsApi from "modules/api/JobsApi";
import { JOB_STATUS } from "modules/jobs/types";
import { appStore } from "../../../../index";
import { nodeListFetchRequested } from "components/management/node/actions";
import { hostListFetchRequested } from "components/management/host/actions";
import { clusterListFetchRequested } from "components/management/cluster/actions";

function* deployHost(action: NodeCreateWizardDeployHostRequestedAction) {
	console.log("deployHost side effect", action);
	try {
		const response = yield call(HostsApi.create, action.payload.host);

		console.log("deployHostResponse", response);
		yield put(
			nodeCreateWizardDeployHostJobStarted({
				id: response.data.id,
				status: response.data.status
			})
		);
	} catch (e) {
		console.error("deployHostError", e.response.data);
		yield put(
			nodeCreateWizardDeployHostFailed(`Host deploy error: ${e.response.data}`)
		);
	}
}

function* monitorDeployHostJob(
	action: NodeCreateWizardDeployHostJobStartedAction
) {
	console.log("host deploy job monitor started", action);

	let jobStatus: JOB_STATUS = action.payload.job.status;

	try {
		while (jobStatus === JOB_STATUS.NEW || jobStatus === JOB_STATUS.RUNNING) {
			const response = yield call(JobsApi.fetch, action.payload.job.id);

			console.log("jobStatus response", response.data.status);
			jobStatus = response.data.status;
		}

		switch (jobStatus) {
			case JOB_STATUS.SUCCESS:
				yield put(nodeCreateWizardDeployHostSucceded());
				yield put(
					nodeCreateWizardInstallNodeRequested(
						appStore.getState().nodeCreateWizard.node
					)
				);
				break;
			case JOB_STATUS.ABORTED:
				yield put(
					nodeCreateWizardDeployHostFailed("Host deploy error: aborted")
				);
				break;
			case JOB_STATUS.FAILURE:
				yield put(
					nodeCreateWizardDeployHostFailed(
						"Host deploy error: failed. See logs for more information."
					)
				);
				break;
			case JOB_STATUS.MISSING:
				yield put(
					nodeCreateWizardDeployHostFailed("Host deploy error: job missing.")
				);
				break;
			case JOB_STATUS.NONE:
				yield put(
					nodeCreateWizardDeployHostFailed(
						"Host deploy error: job not started."
					)
				);
				break;
			default:
				yield put(
					nodeCreateWizardDeployHostFailed("Host deploy error: unknown error.")
				);
		}
	} catch (e) {
		console.error("jobMonitor error", e);
		nodeCreateWizardDeployHostFailed(`Host deploy error: ${e.message}`);
	}
}

function* deleteHost(action: NodeCreateWizardDeleteHostRequestedAction) {
	try {
		const response = yield call(HostsApi.delete, action.payload.host);

		console.log("deleteHostResponse", response);
		yield put(
			nodeCreateWizardDeleteHostJobStarted({
				id: response.data.id,
				status: response.data.status
			})
		);
	} catch (e) {
		console.error("deleteHostError", e.response.data);
		yield put(
			nodeCreateWizardDeployHostFailed(`Host delete error: ${e.response.data}`)
		);
	}
}

function* monitorDeleteHostJob(
	action: NodeCreateWizardDeleteHostJobStartedAction
) {
	console.log("host delete job monitor started", action);

	let jobStatus: JOB_STATUS = action.payload.job.status;

	try {
		while (jobStatus === JOB_STATUS.NEW || jobStatus === JOB_STATUS.RUNNING) {
			const response = yield call(JobsApi.fetch, action.payload.job.id);

			console.log("jobStatus response", response.data.status);
			jobStatus = response.data.status;
		}

		switch (jobStatus) {
			case JOB_STATUS.SUCCESS:
				yield put(nodeCreateWizardDeleteHostSucceded());
				break;
			case JOB_STATUS.ABORTED:
				yield put(
					nodeCreateWizardDeleteHostFailed("Host delete error: aborted")
				);
				break;
			case JOB_STATUS.FAILURE:
				yield put(
					nodeCreateWizardDeleteHostFailed(
						"Host delete error: failed. See logs for more information."
					)
				);
				break;
			case JOB_STATUS.MISSING:
				yield put(
					nodeCreateWizardDeleteHostFailed("Host delete error: job missing.")
				);
				break;
			case JOB_STATUS.NONE:
				yield put(
					nodeCreateWizardDeleteHostFailed(
						"Host delete error: job not started."
					)
				);
				break;
			default:
				yield put(
					nodeCreateWizardDeleteHostFailed("Host delete error: unknown error.")
				);
		}
	} catch (e) {
		console.error("jobMonitor error", e);
		nodeCreateWizardDeleteHostFailed(`Host delete error: ${e.message}`);
	}
}

function* installNode(action: NodeCreateWizardInstallNodeRequestedAction) {
	console.log("installNode side effect", action);

	try {
		const response = yield call(NodesApi.create, action.payload.node);

		console.log("install node success", response);

		yield put(
			nodeCreateWizardInstallNodeJobStarted({
				id: response.data.id,
				status: response.data.status
			})
		);
	} catch (e) {
		console.error("install node error", e.response.data);
		yield put(
			nodeCreateWizardInstallNodeFailed(
				`Install node error: ${e.response.data}`
			)
		);
	}
}

function* monitorInstallNodeJob(
	action: NodeCreateWizardInstallNodeJobStartedAction
) {
	console.log("node install job monitor started", action);

	let jobStatus: JOB_STATUS = action.payload.job.status;

	try {
		while (jobStatus === JOB_STATUS.NEW || jobStatus === JOB_STATUS.RUNNING) {
			const response = yield call(JobsApi.fetch, action.payload.job.id);

			console.log("jobStatus response", response.data.status);
			jobStatus = response.data.status;
		}

		switch (jobStatus) {
			case JOB_STATUS.SUCCESS:
				yield put(nodeCreateWizardInstallNodeSucceded());
				yield put(nodeListFetchRequested());
				yield put(hostListFetchRequested());
				yield put(clusterListFetchRequested());
				break;
			case JOB_STATUS.ABORTED:
				yield put(
					nodeCreateWizardInstallNodeFailed("Node install error: aborted")
				);
				break;
			case JOB_STATUS.FAILURE:
				yield put(
					nodeCreateWizardInstallNodeFailed(
						"Node install error: failed. See logs for more information."
					)
				);
				break;
			case JOB_STATUS.MISSING:
				yield put(
					nodeCreateWizardInstallNodeFailed("Node install error: job missing.")
				);
				break;
			case JOB_STATUS.NONE:
				yield put(
					nodeCreateWizardInstallNodeFailed(
						"Node install error: job not started."
					)
				);
				break;
			default:
				yield put(
					nodeCreateWizardInstallNodeFailed(
						"Node install error: unknown error."
					)
				);
		}
	} catch (e) {
		console.error("jobMonitor error", e);
		yield put(
			nodeCreateWizardInstallNodeFailed(`Node install error: ${e.message}`)
		);
	}
}

function* NodeCreateWizardSideEffects() {
	yield takeEvery(NODE_CREATE_WIZARD_ACTION.DEPLOY_HOST_REQUESTED, deployHost);
	yield takeEvery(
		NODE_CREATE_WIZARD_ACTION.INSTALL_NODE_REQUESTED,
		installNode
	);
	yield takeEvery(
		NODE_CREATE_WIZARD_ACTION.DEPLOY_HOST_JOB_STARTED,
		monitorDeployHostJob
	);
	yield takeEvery(
		NODE_CREATE_WIZARD_ACTION.INSTALL_NODE_JOB_STARTED,
		monitorInstallNodeJob
	);

	yield takeEvery(NODE_CREATE_WIZARD_ACTION.DELETE_HOST_REQUESTED, deleteHost);
	yield takeEvery(
		NODE_CREATE_WIZARD_ACTION.DELETE_HOST_JOB_STARTED,
		monitorDeleteHostJob
	);

	// todo: unsubscribe from ws log listener after deployment is done
}

export default NodeCreateWizardSideEffects;
