import {
	Avatar,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Theme,
	withStyles,
	WithStyles,
	WithTheme
} from "@material-ui/core";
import { Storage } from "@material-ui/icons";
import { AppState } from "AppState";
import React from "react";
import { connect } from "react-redux";
import { StaticContext } from "react-router";
import { RouteComponentProps, withRouter } from "react-router-dom";

const styles = (theme: Theme) => ({
	doubleNested: {
		paddingLeft: theme.spacing(6)
	}
});

// component local state interface
interface State {
	isSelected: boolean;
}

// PROPS
interface ComponentProps {
	node: any;
}

interface DispatchProps {}

type Props = ComponentProps &
	DispatchProps &
	WithStyles<typeof styles> &
	WithTheme &
	RouteComponentProps<any, StaticContext, any>;

// COMPONENT
class NodeItemComponent extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = { isSelected: false };
	}

	handleClick = () => {
		this.props.history.push(`/management/nodes/${this.props.node.name}`);
	};

	componentDidUpdate(prevProps: Props) {
		if (this.props.location !== prevProps.location) {
			this.onRouteChanged();
		}
	}

	onRouteChanged() {
		const isSelected =
			this.props.location.pathname ===
			`/management/nodes/${this.props.node.name}`;

		if (isSelected !== this.state.isSelected) {
			this.setState((state: State) => ({
				...state,
				isSelected
			}));
		}
	}

	render(): React.ReactNode {
		const { theme } = this.props;
		const { isSelected } = this.state;

		return (
			<>
				<ListItem
					selected={isSelected}
					button
					className={this.props.classes.doubleNested}
					onClick={this.handleClick}
				>
					<ListItemAvatar>
						<Avatar
							style={
								isSelected
									? { backgroundColor: theme.palette.primary.main }
									: {}
							}
						>
							<Storage />
						</Avatar>
					</ListItemAvatar>
					<ListItemText
						primary={this.props.node.name}
						secondary={this.props.node.dbEngine}
					/>
				</ListItem>
			</>
		);
	}
}

// REDUX MAPPINGS
const mapGlobalStateToProps = (state: AppState) => {
	return {};
};

const mapGlobalDispatchToProps = (dispatch: any) => {
	return {};
};

export default withStyles(styles, { withTheme: true })(
	withRouter(
		connect(
			mapGlobalStateToProps,
			mapGlobalDispatchToProps
		)(NodeItemComponent)
	)
);
