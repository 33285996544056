import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { withStyles, WithStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import {
	ArrowBack,
	DeviceHub,
	ExitToApp,
	Info,
	MultilineChart,
	Settings,
	SupervisorAccount
} from "@material-ui/icons";
import MenuIcon from "@material-ui/icons/Menu";
import { AppState } from "AppState";
import gc_logo from "assets/images/gc_logo.png";
import logo from "assets/images/gc_logo_orange.png";
import TreeViewComponent from "components/management/treeView/TreeViewComponent";
import { History } from "history";
import Auth from "modules/auth/Auth";
import { showSnackbar } from "components/sharedComponents/snackbar/actionCreators";
import { SnackbarActionPayload } from "components/sharedComponents/snackbar/types";
import React from "react";
import { connect } from "react-redux";
import {
	Link,
	Route,
	RouteComponentProps,
	Switch,
	withRouter
} from "react-router-dom";
import "./RootComponent.sass";
import ClusterComponent from "components/management/cluster/ClusterManagerComponent";
import { Grid } from "@material-ui/core";
import NodeComponent from "components/management/node/NodeComponent";
import { styles } from "./styles";
import Box from "@material-ui/core/Box";
import ClusterCreateDialogComponent from "components/management/cluster/clusterCreateWizard/ClusterCreateDialogComponent";
import NodeCreateDialogComponent from "components/management/node/nodeCreateWizard/NodeCreateDialogComponent";
import AppInfo from "modules/appInfo/AppInfoComponent";
import HiddenJs from "@material-ui/core/Hidden/HiddenJs";
import { PreloaderState } from "modules/preloader/types";
import Preloader from "modules/preloader/PreloaderComponent";
import { StaticContext } from "react-router";
import UserListComponent from "components/users/UserListComponent";

interface LocalState {
	mobileOpen: boolean;
	showBackButton: boolean;
}

interface LocalProps {
	history?: History;
}

interface ReduxStateProps {
	preloader: PreloaderState;
}

interface ReduxDispatchProps {
	showSnackbar: (snackbar: SnackbarActionPayload) => void;
}

type Props = LocalProps &
	ReduxDispatchProps &
	ReduxStateProps &
	WithStyles<typeof styles> &
	RouteComponentProps<any, StaticContext, any>;

class RootComponent extends React.Component<Props, LocalState> {
	history: History;

	constructor(props: any) {
		super(props);
		this.history = props.history;

		this.state = {
			mobileOpen: false,
			showBackButton: false
		};
	}

	componentDidUpdate(prevProps: Props) {
		if (this.props.location !== prevProps.location) {
			this.updateShowBackButton();
		}
	}

	updateShowBackButton() {
		const { pathname } = this.props.location;

		console.log("updateShowBackButton", pathname);

		this.setState(
			(state: LocalState): LocalState => ({
				...state,
				showBackButton: pathname.split("/").length > 2
			})
		);
	}

	handleDrawerToggle = () => {
		this.setState(state => ({ mobileOpen: !state.mobileOpen }));
	};

	signOut = () => {
		Auth.clearTokens().then(
			() => {
				console.log("logout success");
				this.history.push("/login");
			},
			(errorMsg: string) => {
				console.log("logout error", errorMsg);
			}
		);
	};

	render() {
		const { classes } = this.props;
		const { showBackButton } = this.state;

		const drawer = (
			<div>
				<Hidden smDown>
					<div className={classes.toolbar} />
				</Hidden>
				<div className="logo-container">
					<img
						className="galera-cluster-logo"
						src={gc_logo}
						alt="Galera Cluster Manager"
					/>
				</div>
				<Divider />
				<List>
					<ListItem button key={1}>
						<ListItemIcon>
							<MultilineChart />
						</ListItemIcon>
						<ListItemText primary={"Monitoring"} />
					</ListItem>
					<Link
						to="/management"
						onClick={() => {
							this.setState(
								(state: LocalState): LocalState => ({
									...state,
									mobileOpen: false
								})
							);
						}}
					>
						<ListItem button key={2}>
							<ListItemIcon>
								<DeviceHub />
							</ListItemIcon>
							<ListItemText primary={"Management"} />
						</ListItem>
					</Link>
					<Link
						to="/users"
						onClick={() => {
							this.setState(
								(state: LocalState): LocalState => ({
									...state,
									mobileOpen: false
								})
							);
						}}
					>
						<ListItem button key={3}>
							<ListItemIcon>
								<SupervisorAccount />
							</ListItemIcon>
							<ListItemText primary={"Users"} />
						</ListItem>
					</Link>
				</List>
				<Divider />
				<List>
					<ListItem button key={1}>
						<ListItemIcon>
							<Settings />
						</ListItemIcon>
						<ListItemText primary={"Settings"} />
					</ListItem>
					<ListItem button key={2}>
						<ListItemIcon>
							<Info />
						</ListItemIcon>
						<ListItemText primary="About" />
					</ListItem>
					<ListItem button onClick={this.signOut} key={3}>
						<ListItemIcon>
							<ExitToApp />
						</ListItemIcon>
						<ListItemText primary={"Logout"} />
					</ListItem>
				</List>
				{process.env.REACT_APP_ENV === "development" && <AppInfo />}
			</div>
		);

		return (
			<>
				{!this.props.preloader.isPreloadDone ? (
					<Box height="100%">
						<Preloader />
					</Box>
				) : (
					<Box className={classes.root} height="100%">
						<CssBaseline />
						<AppBar position="fixed" className={classes.appBar}>
							<Toolbar variant="dense">
								<HiddenJs smUp>
									{showBackButton ? (
										<IconButton
											color="inherit"
											aria-label="Go back"
											onClick={() => {
												this.props.history.goBack();
											}}
											className={classes.menuButton}
										>
											<ArrowBack />
										</IconButton>
									) : (
										<IconButton
											color="inherit"
											aria-label="Open drawer"
											onClick={this.handleDrawerToggle}
											className={classes.menuButton}
										>
											<MenuIcon />
										</IconButton>
									)}
								</HiddenJs>

								<Hidden smUp>
									<img
										className="logo"
										src={logo}
										height="30px"
										alt="Galera Cluster Logo"
									/>
								</Hidden>

								<Typography variant="h6" color="inherit" noWrap>
									Galera Manager
								</Typography>
							</Toolbar>
						</AppBar>
						<nav className={classes.drawer}>
							{/*Mobile*/}
							<HiddenJs smUp>
								<Drawer
									variant="temporary"
									open={this.state.mobileOpen}
									onClose={this.handleDrawerToggle}
									classes={{
										paper: classes.drawerPaper
									}}
								>
									{drawer}
								</Drawer>
							</HiddenJs>
							{/*Desktop*/}
							<HiddenJs xsDown>
								<Drawer
									classes={{
										paper: classes.drawerPaper
									}}
									variant="permanent"
									open
								>
									{drawer}
								</Drawer>
							</HiddenJs>
						</nav>
						<main className={classes.content}>
							{/*Mobile*/}
							<HiddenJs smUp>
								<Grid item className={classes.toolbar} />

								<Route path="/management" exact component={TreeViewComponent} />
								<Route
									path="/management"
									component={ClusterCreateDialogComponent}
								/>
								<Route
									path="/management"
									component={NodeCreateDialogComponent}
								/>
								<Route
									path="/management/clusters/:name"
									exact
									component={ClusterComponent}
								/>
								<Route
									path="/management/nodes/:name"
									exact
									component={NodeComponent}
								/>
							</HiddenJs>

							{/*Desktop*/}
							<HiddenJs xsDown>
								<Grid container direction="column">
									<Grid item className={classes.toolbar} />

									<Grid
										item
										id="container"
										container
										direction="row"
										xs
										spacing={2}
									>
										{/*Dialogs*/}
										<Route
											path="/management"
											component={ClusterCreateDialogComponent}
										/>
										<Route
											path="/management"
											component={NodeCreateDialogComponent}
										/>

										{/*Navigation / Lists*/}
										<Grid item>
											<Route path="/users" component={UserListComponent} />
											<Route path="/management" component={TreeViewComponent} />
										</Grid>

										{/*Details pages*/}
										<Grid item>
											<Switch>
												<Route
													item
													path="/management/clusters/:name"
													exact
													component={ClusterComponent}
												/>
												<Route
													item
													path="/management/nodes/:name"
													exact
													component={NodeComponent}
												/>
											</Switch>

										</Grid>
									</Grid>
								</Grid>
							</HiddenJs>
						</main>
					</Box>
				)}
			</>
		);
	}
}

// REDUX MAPPINGS
const mapGlobalStateToProps = (state: AppState) => ({
	preloader: state.preloader
});

const mapGlobalDispatchToProps = (dispatch: any) => {
	return {
		showSnackbar: (snackbar: SnackbarActionPayload) => {
			dispatch(showSnackbar(snackbar));
		}
	};
};

export default withStyles(styles, { withTheme: true })(
	withRouter(
		connect(
			mapGlobalStateToProps,
			mapGlobalDispatchToProps
		)(RootComponent)
	)
);
