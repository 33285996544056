import { User } from "components/users/types";

export interface UserSessionState {
	isActive: boolean;
	session?: UserSession;
}

export interface Credentials {
	username: string;
	password: string;
}

export interface UserSession {
	user: User;
	accessExpirationTimestamp: number;
	refreshExpirationTimestamp: number;
}

export enum USER_SESSION_ACTION {
	START_SESSION = "USER_SESSION/START_SESSION",
	END_SESSION = "USER_SESSION/END_SESSION",
	EXTEND_DURATION = "USER_SESSION/EXTEND_DURATION"
}

export interface UserSessionStartAction {
	type: USER_SESSION_ACTION.START_SESSION;
	payload: {
		session: UserSession;
	};
}

export interface UserSessionEndAction {
	type: USER_SESSION_ACTION.END_SESSION;
}

export interface UserSessionExtendAction {
	type: USER_SESSION_ACTION.EXTEND_DURATION;
	payload: {
		accessExpirationTimestamp: number;
	};
}

export type UserSessionAction =
	| UserSessionStartAction
	| UserSessionEndAction
	| UserSessionExtendAction;
