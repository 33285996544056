import Axios, { AxiosResponse } from "axios";
import { Node } from "components/management/node/types";
import AppEnvironment from "modules/appEnvironment";

let nodes: Array<Node> = [];

class NodesApi {
	static async fetchList(): Promise<AxiosResponse> {
		const response = await Axios.get(
			`${AppEnvironment.getApiHostAddress()}/${AppEnvironment.getApiVersion()}/nodes`
		);
		nodes = response.data;
		return response;
	}

	static fetch(name: string): any {
		return nodes.find((node: Node) => {
			return node.name === name;
		});
	}

	static async create(node: Node): Promise<AxiosResponse> {
		return await Axios.post(
			`${AppEnvironment.getApiHostAddress()}/${AppEnvironment.getApiVersion()}/nodes`,
			node
		);
	}

	static async start(node: Node): Promise<AxiosResponse> {
		return await Axios.patch(`${AppEnvironment.getApiHostAddress()}/${AppEnvironment.getApiVersion()}/nodes/${node.name}`,
			{ run: true });
	};
}

export default NodesApi;
