import {
	Button,
	List,
	ListSubheader,
	Paper,
	withStyles,
	WithStyles
} from "@material-ui/core";
import { AppState } from "AppState";
import { clusterListFetchRequested } from "components/management/cluster/actions";
import { Cluster } from "components/management/cluster/types";
import { hostListFetchRequested } from "components/management/host/actions";
import { Host } from "components/management/host/types";
import { nodeListFetchRequested } from "components/management/node/actions";
import { Node } from "components/management/node/types";
import ClusterItemComponent from "components/management/treeView/clusterItem/ClusterItemComponent";
import { showSnackbar } from "components/sharedComponents/snackbar/actionCreators";
import { SnackbarActionPayload } from "components/sharedComponents/snackbar/types";
import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { styles } from "./styles";
import { clusterCreateWizardShow } from "components/management/cluster/clusterCreateWizard/actions";

// PROPS
interface StateProps {
	clusterList: Cluster[];
	nodeList: Node[];
	hostList: Host[];
}

interface DispatchProps {
	showSnackbar: (snackbar: SnackbarActionPayload) => void;
	clusterListFetchRequested: () => void;
	nodeListFetchRequested: () => void;
	hostListFetchRequested: () => void;
	clusterCreateDialogShow: () => void;
}

// component local state interface
interface State {
	treeViewData: any[];
}

type Props = StateProps &
	DispatchProps &
	WithStyles<typeof styles> &
	RouteComponentProps<{}>;

// COMPONENT
class TreeViewComponent extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.requestDataFetch();

		this.state = {
			treeViewData: this.transformData(
				props.clusterList,
				props.nodeList,
				props.hostList
			)
		};
	}

	componentWillReceiveProps = (newProps: Props) => {
		this.setState({
			treeViewData: this.transformData(
				newProps.clusterList,
				newProps.nodeList,
				newProps.hostList
			)
		});
	};

	requestDataFetch = () => {
		this.props.clusterListFetchRequested();
		this.props.nodeListFetchRequested();
		this.props.hostListFetchRequested();
	};

	transformData = (clusters: any, nodes: any, hosts: any) => {
		// clusters
		//    segments
		//       nodes
		const treeViewData: any[] = clusters;

		treeViewData.forEach((cluster: any) => {
			cluster.segments = [];

			nodes.forEach((node: any) => {
				if (node.cluster === cluster.name) {
					const segment = {
						name: `Segment ${node.segment}`,
						segmentId: node.segment,
						nodes: []
					};

					const existingSegmentIndex = cluster.segments.findIndex(
						(segment2: any) => {
							return segment.segmentId === segment2.segmentId;
						}
					);

					if (existingSegmentIndex === -1) {
						cluster.segments.push(segment);
						cluster.segments[cluster.segments.length - 1].nodes.push(node);
					} else {
						cluster.segments[existingSegmentIndex].nodes.push(node);
					}
					// console.log("cluster", cluster);
				}
			});
		});

		return treeViewData.sort((cluster1: Cluster, cluster2: Cluster) => {
			return cluster1.name
				.toLowerCase()
				.localeCompare(cluster2.name.toLowerCase());
		});
	};

	render(): React.ReactNode {
		return (
			<>
				<Paper className={this.props.classes.root}>
					<List subheader={<ListSubheader>Managed clusters</ListSubheader>}>
						{this.state.treeViewData.map((cluster: Cluster) => {
							return (
								<ClusterItemComponent key={cluster.name} cluster={cluster} />
							);
						})}
					</List>
				</Paper>
				<Button
					fullWidth={true}
					onClick={() => {
						this.props.clusterCreateDialogShow();
						// this.props.history.push(`/management/clusters/new`);
					}}
				>
					Create new cluster
				</Button>
			</>
		);
	}
}

// REDUX MAPPINGS
const mapGlobalStateToProps = (state: AppState) => ({
	clusterList: state.clusterList,
	nodeList: state.nodeList,
	hostList: state.hostList
});

const mapGlobalDispatchToProps = (dispatch: any) => ({
	showSnackbar: (snackbar: SnackbarActionPayload) => {
		dispatch(showSnackbar(snackbar));
	},
	clusterListFetchRequested: () => {
		dispatch(clusterListFetchRequested());
	},
	nodeListFetchRequested: () => {
		dispatch(nodeListFetchRequested());
	},
	hostListFetchRequested: () => {
		dispatch(hostListFetchRequested());
	},
	clusterCreateDialogShow: () => {
		dispatch(clusterCreateWizardShow());
	}
});

export default withStyles(styles)(
	connect(
		mapGlobalStateToProps,
		mapGlobalDispatchToProps
	)(TreeViewComponent)
);
