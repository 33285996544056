import { call, put, takeLatest } from "redux-saga/effects";
import NodesApi from "modules/api/NodesApi";
import {
	NODE_ACTION,
	NodeCreateRequestedAction
} from "components/management/node/types";
import {
	nodeListFetchSucceded,
	nodeCreateSucceded
} from "components/management/node/actions";

function* fetchNodeList() {
	// console.log("fetchNodeList saga");

	try {
		const response = yield call(NodesApi.fetchList);
		// console.log("saga node list fetched", response);

		yield put(nodeListFetchSucceded(response.data));
	} catch (e) {
		console.error(e);
	}
}

function* createNode(action: NodeCreateRequestedAction) {
	yield call(NodesApi.create, action.payload.node);
	yield put(nodeCreateSucceded(action.payload.node));
}

function* NodeSideEffects() {
	yield takeLatest(NODE_ACTION.LIST_FETCH_REQUESTED, fetchNodeList);
	yield takeLatest(NODE_ACTION.CREATE_REQUESTED, createNode);
}

export default NodeSideEffects;
