import { Theme, createStyles } from "@material-ui/core";

export const styles = (theme: Theme) =>
	createStyles({
		modal: {
			alignItems: "center",
			justifyContent: "center",
			display: "flex"
		},
		card: {
			width: "100%",
			[theme.breakpoints.up("sm")]: {
				width: 700
			}
		},
		logTable: {
			height: "500px"
		}
	});
