import {
	PRELOADER_ACTION,
	PreloaderAction,
	PreloaderState
} from "modules/preloader/types";

const initialState: PreloaderState = {
	isPreloadDone: false,
	errorMsg: ""
};

export function preloaderReducer(
	state: PreloaderState = initialState,
	action: PreloaderAction
): PreloaderState {
	switch (action.type) {
		case PRELOADER_ACTION.PRELOAD_SUCCEDED:
			return { ...state, isPreloadDone: true };
		case PRELOADER_ACTION.PRELOAD_FAILED:
			return { ...state, errorMsg: action.payload.reason };
		default:
			return state;
	}
}
