import Axios, { AxiosResponse } from "axios";
import { Cluster } from "components/management/cluster/types";
import AppEnvironment from "modules/appEnvironment";

let clusterList: Array<any> = [];

class ClustersApi {
	static async fetchList(): Promise<AxiosResponse> {
		const clusterResponse = await Axios.get(
			`${AppEnvironment.getApiHostAddress()}/${AppEnvironment.getApiVersion()}/clusters`
		);
		clusterList = clusterResponse.data;
		return clusterResponse;
	}

	static fetch(name: string): any {
		return clusterList.find((cluster: any) => {
			return cluster.name === name;
		});
	}

	static async create(cluster: Cluster): Promise<AxiosResponse> {
		return await Axios.post(
			`${AppEnvironment.getApiHostAddress()}/${AppEnvironment.getApiVersion()}/clusters`,
			cluster
		);
	}

	static async delete(cluster: Cluster): Promise<AxiosResponse> {
		return await Axios.delete(
			`${AppEnvironment.getApiHostAddress()}/${AppEnvironment.getApiVersion()}/clusters/${
				cluster.name
				}?recursive=1`
		);
	}
}

export default ClustersApi;
