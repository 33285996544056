import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) =>
	createStyles({
		card: {
			width: "100%",
			[theme.breakpoints.up("sm")]: {
				width: 700
			}
		},
		bullet: {
			display: "inline-block",
			margin: "0 2px",
			transform: "scale(0.8)"
		},
		title: {
			fontSize: 14
		},
		pos: {
			marginBottom: 12
		}
	});
