import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import { User } from "components/users/types";
import { createBrowserHistory, History } from "history";
import { TokenExpiredError } from "jsonwebtoken";
import Auth from "modules/auth/Auth";
import { initializeInterceptors } from "modules/Interceptors";
import { showSnackbar } from "components/sharedComponents/snackbar/actionCreators";
import SnackbarSideEffects from "components/sharedComponents/snackbar/sideEffects";
import GMSnackbar from "components/sharedComponents/snackbar/SnackbarComponent";
import { startSession } from "modules/userSession/actions";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { applyMiddleware, compose, createStore, Store } from "redux";
import createSagaMiddleware from "redux-saga";
import { rootReducer } from "./AppState";
import GMRouter from "./components/Router";
import "./index.sass";
import * as serviceWorker from "./serviceWorker";
import UserSideEffects from "components/users/sideEffects";
import ClusterSideEffects from "components/management/cluster/sideEffects";
import NodeSideEffects from "components/management/node/sideEffects";
import HostSideEffects from "components/management/host/sideEffects";
import JobSideEffects from "modules/jobs/sideEffects";
import NodeCreateWizardSideEffects from "components/management/node/nodeCreateWizard/sideEffects";
import ClusterCreateWizardSideEffects from "components/management/cluster/clusterCreateWizard/sideEffects";
import { WebSocketHandlerSideEffects } from "modules/webSocketHandler/sideEffects";
import PreloaderSideEffects from "modules/preloader/sideEffects";

const sagaMiddleware = createSagaMiddleware();

export const appStore: Store = createStore(
	rootReducer,
	compose(
		applyMiddleware(sagaMiddleware),
		((window as any).__REDUX_DEVTOOLS_EXTENSION__ &&
			(window as any).__REDUX_DEVTOOLS_EXTENSION__()) ||
			compose
	)
);

sagaMiddleware.run(SnackbarSideEffects);
sagaMiddleware.run(UserSideEffects);
sagaMiddleware.run(ClusterSideEffects);
sagaMiddleware.run(NodeSideEffects);
sagaMiddleware.run(HostSideEffects);
sagaMiddleware.run(JobSideEffects);
sagaMiddleware.run(ClusterCreateWizardSideEffects);
sagaMiddleware.run(NodeCreateWizardSideEffects);
sagaMiddleware.run(WebSocketHandlerSideEffects);
sagaMiddleware.run(PreloaderSideEffects);

// setTimeout(() => {
// 	// 	// console.log("sendTestMessage");
// 	// 	// webSocketHandlerConnectionMessageSendRequested("test");
// 	// 	// appStore.dispatch(webSocketHandlerConnectionCloseRequestedAction());
// 	appStore.dispatch(
// 		webSocketHandlerConnectionMessageSendRequested({
// 			type: "subscribe",
// 			value: `host:h13332`
// 		})
// 	);
// }, 4000);

const theme = createMuiTheme({
	palette: {
		primary: {
			light: "#4d78c6",
			main: "#004d95",
			dark: "#002666",
			contrastText: "#fff"
		},
		secondary: {
			light: "#ffa742",
			main: "#ed7703",
			dark: "#b44900",
			contrastText: "#000"
		}
	}
});

const history: History = createBrowserHistory();

initializeInterceptors(appStore, history);

// initialize sessions
if (history.location.pathname !== "/login") {
	try {
		let user: User;

		const refreshTokenData = Auth.verifyJwtToken(Auth.getRefreshToken());
		user = {
			name: refreshTokenData.username,
			role: refreshTokenData.userRole
		};

		let accessExpirationTimestamp: number;
		try {
			const accessTokenData = Auth.verifyJwtToken(Auth.getHttpAccessToken());
			accessExpirationTimestamp = accessTokenData.exp;
		} catch (e) {
			accessExpirationTimestamp = 0;
		}

		appStore.dispatch(
			startSession({
				user,
				accessExpirationTimestamp,
				refreshExpirationTimestamp: refreshTokenData.exp
			})
		);
	} catch (e) {
		switch (e.constructor) {
			case TokenExpiredError:
				appStore.dispatch(
					showSnackbar({ msg: "Session expired. Please login again." })
				);
				history.push("/login");
				break;
			default:
				history.push("/login");
		}
	}
}

ReactDOM.render(
	<Provider store={appStore}>
		<MuiThemeProvider theme={theme}>
			<GMRouter history={history} />
			<GMSnackbar />
		</MuiThemeProvider>
	</Provider>,
	document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
