import {
	CLUSTER_CREATE_WIZARD_ACTION,
	ClusterCreateWizardCreateFailedAction,
	ClusterCreateWizardCreateRequestedAction,
	ClusterCreateWizardCreateSuccededAction,
	ClusterCreateWizardHideAction,
	ClusterCreateWizardSetClusterAction,
	ClusterCreateWizardShowAction
} from "components/management/cluster/clusterCreateWizard/types";
import { Cluster } from "components/management/cluster/types";

export const clusterCreateWizardShow = (): ClusterCreateWizardShowAction => ({
	type: CLUSTER_CREATE_WIZARD_ACTION.SHOW
});

export const clusterCreateWizardHide = (): ClusterCreateWizardHideAction => ({
	type: CLUSTER_CREATE_WIZARD_ACTION.HIDE
});

export const clusterCreateWizardSetCluster = (
	cluster: Cluster
): ClusterCreateWizardSetClusterAction => ({
	type: CLUSTER_CREATE_WIZARD_ACTION.SET_CLUSTER,
	payload: {
		cluster
	}
});

export const clusterCreateWizardCreateRequested = (
	cluster: Cluster
): ClusterCreateWizardCreateRequestedAction => ({
	type: CLUSTER_CREATE_WIZARD_ACTION.CREATE_REQUESTED,
	payload: {
		cluster
	}
});

export const clusterCreateWizardCreateSucceded = (): ClusterCreateWizardCreateSuccededAction => ({
	type: CLUSTER_CREATE_WIZARD_ACTION.CREATE_SUCCEDED
});

export const clusterCreateWizardCreateFailed = (
	reason: string
): ClusterCreateWizardCreateFailedAction => ({
	type: CLUSTER_CREATE_WIZARD_ACTION.CREATE_FAILED,
	payload: {
		reason
	}
});
