import { NODE_DB_ENGINE } from "components/management/node/types";

class NodeUtils {
	static getNodeDbEngineEnum = (value: string): NODE_DB_ENGINE => {
		switch (value) {
			case NODE_DB_ENGINE.MARIADB_10_3:
				return NODE_DB_ENGINE.MARIADB_10_3;
			default:
				console.warn(
					`Unrecognised node DB engine: ${value}. Returning default value instead.`
				);
				return NODE_DB_ENGINE.MARIADB_10_3;
		}
	};
}

export default NodeUtils;
