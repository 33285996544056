import {
	Divider,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Typography
} from "@material-ui/core";
import { withStyles, WithStyles } from "@material-ui/core/styles";
import { AppState } from "AppState";
import { Cluster } from "components/management/cluster/types";
import React, { ChangeEvent, FormEvent } from "react";
import { connect } from "react-redux";
import { styles } from "./styles";
import { HOST_SYSTEM, HOST_TYPE } from "components/management/host/types";
import { NODE_DB_ENGINE } from "components/management/node/types";
import { RouteComponentProps, StaticContext, withRouter } from "react-router";
import PrivateKeyEditor from "components/sharedComponents/privateKeyEditor/PrivateKeyEditorComponent";
import AuthorizedKeysEditor from "components/sharedComponents/authorizedKeysEditor/AuthorizedKeysEditorComponent";
import NodeUtils from "components/management/node/utils";
import HostUtils from "components/management/host/utils";

// component local state interface
interface LocalState {
	cluster: Cluster;
	formValidation: {
		clusterName: {
			invalid: boolean;
			message: string;
		};
	};
}

interface LocalProps {
	cluster: Cluster;
	privateKeySubtitle?: string;
	authorizedKeysSubtitle?: string;
	onSubmit?: (cluster: Cluster) => void;
	readOnly?: boolean;
}

// PROPS
interface ReduxStateProps {}

interface ReduxDispatchProps {
	// showSnackbar: (snackbar: SnackbarActionPayload) => void;
	// clusterCreateWizardCreateRequested: (cluster: Cluster) => void;
	// clusterCreateWizardHide: () => void;
	// clusterCreateWizardSetCluster: (cluster: Cluster) => void;
	// nodeCreateWizardShow: (cluster: Cluster) => void;
}

type Props = LocalProps &
	ReduxStateProps &
	ReduxDispatchProps &
	WithStyles<typeof styles> &
	RouteComponentProps<any, StaticContext, any>;

// COMPONENT
class ClusterFormComponent extends React.Component<Props, LocalState> {
	constructor(props: Props) {
		super(props);

		console.log("clusterForm", props);
		this.state = {
			cluster: props.cluster,
			formValidation: {
				clusterName: {
					invalid: false,
					message: ""
				}
			}
		};
	}

	onSubmit = (e: FormEvent) => {
		e.preventDefault();
		console.log("onSubmit", e);
		if (this.props.readOnly !== false && this.props.onSubmit) {
			this.props.onSubmit(this.state.cluster);
		}
		// this.props.clusterCreateWizardCreateRequested(this.props.cluster);
	};

	render(): React.ReactNode {
		const { authorizedKeysSubtitle, privateKeySubtitle, readOnly } = this.props;
		const { cluster } = this.state;

		return (
			<form id="clusterForm" onSubmit={this.onSubmit}>
				<Grid container direction="column" spacing={2}>
					<Grid container item direction="column">
						<Grid item>
							<Typography variant="subtitle2">Cluster configuration</Typography>
						</Grid>
						<Grid container item direction="row" spacing={2}>
							<Grid item xs={12} sm={6}>
								<FormControl
									onInvalid={(e: FormEvent): void => {
										e.preventDefault();
										const form = e.target as HTMLFormElement;

										this.setState((state: LocalState) => ({
											...state,
											formValidation: {
												...state.formValidation,
												clusterName: {
													...state.formValidation.clusterName,
													invalid: true,
													message: form.validationMessage
												}
											}
										}));
									}}
									error={this.state.formValidation.clusterName.invalid}
									fullWidth={true}
									required
								>
									<TextField
										label="Cluster name"
										error={this.state.formValidation.clusterName.invalid}
										autoFocus={!readOnly}
										required
										autoComplete="off"
										inputProps={{
											readOnly: readOnly,
											maxLength: 30
										}}
										margin="dense"
										name="clusterName"
										helperText={this.state.formValidation.clusterName.message}
										value={cluster.name}
										onChange={(e: ChangeEvent) => {
											const field = e.target as HTMLFormElement;

											this.setState(
												(state: LocalState): LocalState => ({
													...state,
													cluster: {
														...state.cluster,
														name: field.value
													}
												})
											);

											if (field.checkValidity()) {
												this.setState((state: LocalState) => ({
													formValidation: {
														...state.formValidation,
														clusterName: {
															...state.formValidation.clusterName,
															invalid: false,
															message: ""
														}
													}
												}));
											} else {
												this.setState((state: LocalState) => ({
													...state,
													formValidation: {
														...state.formValidation,
														clusterName: {
															...state.formValidation.clusterName,
															invalid: true,
															message: field.validationMessage
														}
													}
												}));
											}
										}}
									/>
								</FormControl>
							</Grid>
						</Grid>
					</Grid>
					<Grid container item direction="column">
						<Grid item>
							<Typography variant="subtitle2">
								Host defaults configuration
							</Typography>
						</Grid>
						<Grid container direction="row" spacing={2}>
							<Grid item sm={6} xs={12}>
								<FormControl fullWidth={true} margin="dense">
									<InputLabel htmlFor="host-type">Host type</InputLabel>
									<Select
										fullWidth={true}
										readOnly={readOnly}
										value={cluster.hostDefaults.type}
										onChange={e => {
											const value = e.target.value as string;

											this.setState(
												(state: LocalState): LocalState => ({
													...state,
													cluster: {
														...state.cluster,
														hostDefaults: {
															...state.cluster.hostDefaults,
															type: HostUtils.getHostTypeEnum(value)
														}
													}
												})
											);
										}}
										inputProps={{
											id: "host-type"
										}}
									>
										{Object.values(HOST_TYPE).map((type: string) => (
											<MenuItem key={type} value={type}>
												{type}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid>
							<Grid item sm={6} xs={12}>
								<FormControl fullWidth={true} margin="dense">
									<InputLabel htmlFor="host-system">Host system</InputLabel>
									<Select
										fullWidth={true}
										readOnly={readOnly}
										value={cluster.hostDefaults.system}
										onChange={e => {
											const value = e.target.value as string;
											this.setState(
												(state: LocalState): LocalState => ({
													...state,
													cluster: {
														...state.cluster,
														hostDefaults: {
															...state.cluster.hostDefaults,
															system: HostUtils.getHostSystemEnum(value)
														}
													}
												})
											);
										}}
										inputProps={{
											id: "host-system"
										}}
									>
										{Object.values(HOST_SYSTEM).map((system: string) => (
											<MenuItem key={system} value={system}>
												{system}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid>
						</Grid>
						<Grid container direction="row" spacing={2}>
							<Grid item sm={6} xs={12}>
								<PrivateKeyEditor
									readOnly={readOnly || false}
									privateKey={cluster.hostDefaults.privateKey}
									subtitleText={privateKeySubtitle || ""}
									onSubmit={(privateKey: string) => {
										this.setState(
											(state: LocalState): LocalState => ({
												...state,
												cluster: {
													...state.cluster,
													hostDefaults: {
														...state.cluster.hostDefaults,
														privateKey
													}
												}
											})
										);
									}}
								/>
							</Grid>
							<Grid item sm={6} xs={12}>
								<AuthorizedKeysEditor
									subtitleText={
										authorizedKeysSubtitle ||
										"Below is the list of currently active authorized keys in this cluster."
									}
									readOnly={readOnly || false}
									authorizedKeys={cluster.hostDefaults.authorizedKeys}
									onAdd={(key: string): void => {
										// this.props.clusterCreateWizardSetCluster({
										// 	...cluster,
										// 	hostDefaults: {
										// 		...cluster.hostDefaults,
										// 		authorizedKeys: [
										// 			...this.props.cluster.hostDefaults.authorizedKeys,
										// 			key
										// 		]
										// 	}
										// });
										this.setState(
											(state: LocalState): LocalState => ({
												...state,
												cluster: {
													...state.cluster,
													hostDefaults: {
														...state.cluster.hostDefaults,
														authorizedKeys: [
															...state.cluster.hostDefaults.authorizedKeys,
															key
														]
													}
												}
											})
										);
									}}
									onRemove={(deletedKey: string, index: number): void => {
										const filteredKeys = this.props.cluster.hostDefaults.authorizedKeys.filter(
											(publicKey: string) => publicKey !== deletedKey
										);

										this.setState(
											(state: LocalState): LocalState => ({
												...state,
												cluster: {
													...state.cluster,
													hostDefaults: {
														...state.cluster.hostDefaults,
														authorizedKeys: filteredKeys
													}
												}
											})
										);
										// this.props.clusterCreateWizardSetCluster({
										// 	...cluster,
										// 	hostDefaults: {
										// 		...cluster.hostDefaults,
										// 		authorizedKeys: filteredKeys
										// 	}
										// });
									}}
								/>
							</Grid>
						</Grid>
					</Grid>
					<Grid container item direction="column">
						<Typography variant="subtitle1">Node defaults</Typography>
						<Divider />
						<Grid container direction="row" spacing={2}>
							<Grid item sm={6} xs={12}>
								<FormControl fullWidth={true} margin="dense">
									<InputLabel htmlFor="node-db-engine">
										Node DB Engine
									</InputLabel>
									<Select
										fullWidth={true}
										readOnly={readOnly}
										value={cluster.nodeDefaults.dbEngine}
										onChange={e =>
											// this.props.clusterCreateWizardSetCluster({
											// 	...cluster,
											// 	nodeDefaults: {
											// 		...cluster.nodeDefaults,
											// 		dbEngine: this.getNodeDbEngineEnum(e.target.value)
											// 	}
											// })

											{
												const value = e.target.value as string;

												this.setState(
													(state: LocalState): LocalState => ({
														...state,
														cluster: {
															...state.cluster,
															nodeDefaults: {
																...state.cluster.nodeDefaults,
																dbEngine: NodeUtils.getNodeDbEngineEnum(value)
															}
														}
													})
												);
											}
										}
										inputProps={{
											id: "node-db-engine"
										}}
									>
										{Object.values(NODE_DB_ENGINE).map((db_engine: string) => (
											<MenuItem key={db_engine} value={db_engine}>
												{db_engine}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</form>
		);
	}
}

// REDUX MAPPINGS
const mapGlobalStateToProps = (state: AppState) => ({});

const mapGlobalDispatchToProps = (dispatch: any) => ({
	// showSnackbar: (snackbar: SnackbarActionPayload) => {
	// 	dispatch(showSnackbar(snackbar));
	// },
	// clusterCreateWizardCreateRequested: (cluster: Cluster) => {
	// 	dispatch(clusterCreateWizardCreateRequested(cluster));
	// },
	// clusterCreateWizardHide: () => {
	// 	dispatch(clusterCreateWizardHide());
	// },
	// clusterCreateWizardSetCluster: (cluster: Cluster) => {
	// 	dispatch(clusterCreateWizardSetCluster(cluster));
	// },
	// nodeCreateWizardShow: (cluster: Cluster) => {
	// 	dispatch(nodeCreateWizardShow(cluster));
	// }
});

export default withStyles(styles, { withTheme: true })(
	withRouter(
		connect(
			mapGlobalStateToProps,
			mapGlobalDispatchToProps
		)(ClusterFormComponent)
	)
);
