import React, { ChangeEvent, KeyboardEvent, MouseEvent } from "react";
import { withStyles, WithStyles, WithTheme } from "@material-ui/core/styles";
import { styles } from "./styles";
import {
	Avatar,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	IconButton,
	InputAdornment,
	List,
	ListItem,
	ListItemAvatar,
	ListItemSecondaryAction,
	ListItemText,
	Popover,
	TextField,
	Tooltip,
	Typography
} from "@material-ui/core";
import { AddCircleOutlined, Delete, VpnKey } from "@material-ui/icons";
import * as sshpk from "sshpk";
import HiddenJs from "@material-ui/core/Hidden/HiddenJs";

interface LocalState {
	isDialogOpen: boolean;
	isPopoverOpen: boolean;
	popoverAnchorEl: Element | null;
	popoverContent: string;
	addNewKey: {
		value: string;
		isValid: boolean;
		message: string;
	};
}

interface LocalProps {
	authorizedKeys: string[];
	inheritedAuthorizedKeys?: string[];
	subtitleText: string;
	readOnly: boolean;
	onRemove: (publicKey: string, index: number) => void;
	onAdd: (publicKey: string) => void;
}

type Props = LocalProps & WithStyles<typeof styles> & WithTheme;

class AuthorizedKeysEditor extends React.PureComponent<Props, LocalState> {
	defaultState: LocalState = {
		isDialogOpen: false,
		isPopoverOpen: false,
		popoverAnchorEl: null,
		popoverContent: "",
		addNewKey: {
			value: "",
			isValid: false,
			message: ""
		}
	};

	constructor(props: Props) {
		super(props);

		this.state = this.defaultState;
	}

	onItemClick = (e: MouseEvent): void => {
		this.setState((state: LocalState) => ({
			...state,
			authorizedKeys: this.props.authorizedKeys,
			isDialogOpen: true
		}));
	};

	closeDialog = (): void => {
		this.setState((state: LocalState) => ({ ...state, isDialogOpen: false }));
	};

	// onRemoveClick = (index: number): void => {
	// 	console.log("onRemoveClick", index, this.props.authorizedKeys[index]);
	//
	// 	this.setState((state: LocalState) => ({
	// 		...state,
	// 		authorizedKeys: state.authorizedKeys.splice(index, 1)
	// 	}));
	// };

	onPublicKeyChanged = (e: ChangeEvent<HTMLInputElement>): void => {
		e.persist();
		let isValid = false;
		let message = "";
		const value = e.target.value.trim();

		if (value) {
			try {
				const key = sshpk.parseKey(value, "auto");
				isValid = true;
				message = `Valid ${key.type.toUpperCase()} ${
					key.size
				}bit key. Press ENTER or click "add" icon on the right to save the key.`;
			} catch (e) {
				console.warn("Not valid public key");
				message = "Please enter valid SSH public key";
			}

			if (
				this.props.authorizedKeys.includes(value) ||
				(this.props.inheritedAuthorizedKeys &&
					this.props.inheritedAuthorizedKeys.includes(value))
			) {
				isValid = false;
				message = "Key already in use";
			}
		}

		this.setState((state: LocalState) => ({
			...state,
			addNewKey: {
				...state.addNewKey,
				value,
				isValid,
				message: message
			}
		}));
	};

	addNewKeySubmit = () => {
		this.props.onAdd(this.state.addNewKey.value);
		this.setState((state: LocalState) => ({
			...state,
			addNewKey: {
				...state.addNewKey,
				value: "",
				isValid: false,
				message: ""
			}
		}));
	};

	render() {
		const {
			classes,
			readOnly,
			theme,
			subtitleText,
			authorizedKeys,
			inheritedAuthorizedKeys
		} = this.props;
		const {
			isDialogOpen,
			isPopoverOpen,
			popoverAnchorEl,
			popoverContent,
			addNewKey
		} = this.state;

		let keysCount = authorizedKeys.length;
		let inheritedKeysCount = 0;

		if (inheritedAuthorizedKeys) {
			inheritedKeysCount = inheritedAuthorizedKeys.length;
			keysCount += inheritedKeysCount;
		}

		const ListItemComponent = ListItem as any; // temporary workaround because of bug in TS/MUI: https://github.com/mui-org/material-ui/issues/14971

		const renderKeyItem = (
			publicKey: string,
			index: number,
			inherited = false
		) => {
			const key = sshpk.parseKey(publicKey, "auto");

			return (
				<ListItemComponent
					key={publicKey}
					// button={!readOnly}
					// onClick={(e: MouseEvent): void => {
					// 	e.persist();
					// 	const popoverAnchorEl = e.currentTarget;
					// 	this.setState(
					// 		(state: LocalState): LocalState => ({
					// 			...state,
					// 			isPopoverOpen: true,
					// 			popoverAnchorEl,
					// 			popoverContent: publicKey
					// 		})
					// 	);
					// }}
				>
					<ListItemAvatar>
						<Avatar
							style={{
								backgroundColor: theme.palette.primary.main
							}}
						>
							<VpnKey />
						</Avatar>
					</ListItemAvatar>
					<ListItemText
						primary={key.comment}
						secondary={`${key.type.toUpperCase()} ${key.size} ${
							inherited ? "(Inherited)" : ""
						}`}
					/>
					{!inherited && !readOnly && (
						<ListItemSecondaryAction>
							<Tooltip title={"Remove key"}>
								<IconButton
									onClick={(e: MouseEvent): void => {
										this.props.onRemove(publicKey, index);
									}}
									edge="end"
									aria-label="Delete"
								>
									<Delete />
								</IconButton>
							</Tooltip>
						</ListItemSecondaryAction>
					)}
				</ListItemComponent>
			);
		};

		const renderDialog = () => (
			<>
				{/*Mobile*/}
				<HiddenJs smUp>
					<Dialog
						open={isDialogOpen}
						onClose={() => {}}
						fullWidth={true}
						fullScreen={true}
						disableBackdropClick={true}
						aria-labelledby="form-dialog-title"
					>
						{dialogContentRender()}
					</Dialog>
				</HiddenJs>

				{/*Desktop*/}
				<HiddenJs xsDown>
					<Dialog
						open={isDialogOpen}
						onClose={() => {}}
						fullWidth={true}
						maxWidth={"sm"}
						disableBackdropClick={true}
						aria-labelledby="form-dialog-title"
					>
						{dialogContentRender()}
					</Dialog>
				</HiddenJs>
			</>
		);

		const dialogContentRender = () => (
			<>
				<DialogTitle id="form-dialog-title">Authorized keys</DialogTitle>
				<DialogContent>
					{subtitleText && (
						<DialogContentText variant={"subtitle2"}>
							{subtitleText}
						</DialogContentText>
					)}
					<List className={classes.root}>
						{inheritedAuthorizedKeys &&
							inheritedAuthorizedKeys.map((publicKey: string, index) => {
								return renderKeyItem(publicKey, index, true);
							})}
						{authorizedKeys.map((publicKey: string, index) => {
							return renderKeyItem(publicKey, index);
						})}
					</List>
					{!readOnly && (
						<TextField
							autoFocus
							error={addNewKey.message !== "" && !addNewKey.isValid}
							helperText={addNewKey.message}
							multiline={true}
							margin="dense"
							id="publicKey"
							label="Add SSH Public Key"
							type="text"
							fullWidth
							variant="outlined"
							value={addNewKey.value}
							onChange={this.onPublicKeyChanged}
							onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
								if (e.key === "Enter") {
									this.addNewKeySubmit();
								}
							}}
							InputProps={{
								style: { fontFamily: "monospace" },
								spellCheck: false,
								autoComplete: "off",
								autoCorrect: "off",
								autoCapitalize: "off",
								endAdornment: (
									<InputAdornment position="end">
										<IconButton
											edge="end"
											size="medium"
											color="primary"
											disabled={!addNewKey.isValid}
											aria-label="Add public key"
											onClick={(): void => {
												console.log("onClick");
												this.addNewKeySubmit();
											}}
										>
											{<AddCircleOutlined />}
										</IconButton>
									</InputAdornment>
								)
							}}
						/>
					)}

					<Popover
						open={isPopoverOpen}
						anchorEl={popoverAnchorEl}
						onClose={() => {
							console.log("onClose");
							this.setState(
								(state: LocalState): LocalState => ({
									...state,
									isPopoverOpen: false,
									popoverAnchorEl: null
								})
							);
						}}
						anchorOrigin={{
							vertical: "bottom",
							horizontal: "center"
						}}
						transformOrigin={{
							vertical: "top",
							horizontal: "center"
						}}
					>
						<Typography style={{ padding: theme.spacing(2) }}>
							{popoverContent}
						</Typography>
					</Popover>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={(): void => {
							this.closeDialog();
						}}
					>
						Close
					</Button>
				</DialogActions>
			</>
		);

		return (
			<>
				<List className={classes.root}>
					<ListItemComponent button={true} onClick={this.onItemClick}>
						<ListItemAvatar>
							<Avatar
								style={
									keysCount > 0
										? { backgroundColor: theme.palette.primary.main }
										: {}
								}
							>
								<VpnKey />
							</Avatar>
						</ListItemAvatar>
						<ListItemText
							primary="Authorized keys"
							secondary={
								keysCount > 0
									? `${keysCount} key/s set ${
											inheritedKeysCount > 0
												? `(${inheritedKeysCount} inherited)`
												: ""
									  }`
									: "Not set"
							}
						/>
					</ListItemComponent>
				</List>
				{renderDialog()}
			</>
		);
	}
}

export default withStyles(styles, { withTheme: true })(AuthorizedKeysEditor);
