import Axios, { AxiosResponse } from "axios";
import AppEnvironment from "modules/appEnvironment";

class JobsApi {
	static async fetch(id: number): Promise<AxiosResponse> {
		return await Axios.get(
			`${AppEnvironment.getApiHostAddress()}/${AppEnvironment.getApiVersion()}/jobs/${id}`
		);
	}
}

export default JobsApi;
