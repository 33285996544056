import {
	HOST_ACTION,
	Host,
	HostListFetchSuccededAction
} from "components/management/host/types";

export const hostListFetchRequested = () => ({
	type: HOST_ACTION.LIST_FETCH_REQUESTED
});

export const hostListFetchSucceded = (
	hostList: Host[]
): HostListFetchSuccededAction => ({
	type: HOST_ACTION.LIST_FETCH_SUCCEDED,
	payload: {
		hostList
	}
});
