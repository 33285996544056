import {
	WEB_SOCKET_HANDLER_ACTION,
	WebSocketHandlerConnectionAuthenticationRequestedAction,
	WebSocketHandlerConnectionCloseRequestedAction,
	WebSocketHandlerConnectionCloseSuccededAction,
	WebSocketHandlerConnectionOpenFailedAction,
	WebSocketHandlerConnectionOpenRequestedAction,
	WebSocketHandlerConnectionOpenSuccededAction,
	WebSocketHandlerLogMessageReceivedAction,
	WebSocketHandlerMessageSendRequestedAction
} from "modules/webSocketHandler/types";

export const webSocketHandlerConnectionOpenRequested = (): WebSocketHandlerConnectionOpenRequestedAction => ({
	type: WEB_SOCKET_HANDLER_ACTION.CONNECTION_OPEN_REQUESTED
});

export const webSocketHandlerConnectionOpenSucceded = (): WebSocketHandlerConnectionOpenSuccededAction => ({
	type: WEB_SOCKET_HANDLER_ACTION.CONNECTION_OPEN_SUCCEDED
});

export const webSocketHandlerConnectionFailed = (
	reason: string
): WebSocketHandlerConnectionOpenFailedAction => ({
	type: WEB_SOCKET_HANDLER_ACTION.CONNECTION_OPEN_FAILED,
	payload: { reason }
});

export const webSocketHandlerConnectionAuthenticationRequested = (): WebSocketHandlerConnectionAuthenticationRequestedAction => ({
	type: WEB_SOCKET_HANDLER_ACTION.CONNECTION_AUTHENTICATION_REQUESTED
});

export const webSocketHandlerConnectionCloseRequestedAction = (): WebSocketHandlerConnectionCloseRequestedAction => ({
	type: WEB_SOCKET_HANDLER_ACTION.CONNECTION_CLOSE_REQUESTED
});

export const webSocketHandlerConnectionCloseSuccededAction = (): WebSocketHandlerConnectionCloseSuccededAction => ({
	type: WEB_SOCKET_HANDLER_ACTION.CONNECTION_CLOSE_SUCCEDED
});

export const webSocketHandlerConnectionMessageSendRequested = (
	message: any
): WebSocketHandlerMessageSendRequestedAction => ({
	type: WEB_SOCKET_HANDLER_ACTION.MESSAGE_SEND_REQUESTED,
	payload: {
		message
	}
});

export const webSocketHandlerLogMessageReceived = (message: any): WebSocketHandlerLogMessageReceivedAction => ({
	type: WEB_SOCKET_HANDLER_ACTION.LOG_MESSAGE_RECEIVED,
	payload: {
		message
	}
});