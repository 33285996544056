import {
	Avatar,
	Button,
	CardActions,
	CardHeader,
	Divider,
	WithTheme
} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { withStyles, WithStyles } from "@material-ui/core/styles";
import { DeviceHub } from "@material-ui/icons";
import { AppState } from "AppState";
import { showSnackbar } from "components/sharedComponents/snackbar/actionCreators";
import { SnackbarActionPayload } from "components/sharedComponents/snackbar/types";
import React from "react";
import { connect } from "react-redux";
import { StaticContext } from "react-router";
import { RouteComponentProps } from "react-router-dom";
import ClustersApi from "modules/api/ClustersApi";
import { Cluster } from "components/management/cluster/types";
import { styles } from "./styles";
import { clusterDeleteRequested } from "components/management/cluster/actions";
import { nodeCreateWizardShow } from "components/management/node/nodeCreateWizard/actions";
import ClusterFormComponent from "components/management/cluster/ClusterFormComponent";

// component local state interface
interface LocalState {
	cluster: Cluster;
	anchorEl: any;
}

// PROPS
interface StateProps {
	clusterList: Cluster[];
}

interface DispatchProps {
	showSnackbar: (snackbar: SnackbarActionPayload) => void;
	clusterDeleteRequested: (cluster: Cluster) => void;
	nodeCreateWizardShow: (cluster: Cluster) => void;
}

type Props = StateProps &
	DispatchProps &
	WithStyles<typeof styles> &
	WithTheme &
	RouteComponentProps<any, StaticContext, any>;

// COMPONENT
class ClusterManagerComponent extends React.Component<Props, LocalState> {
	constructor(props: Props) {
		super(props);

		let cluster = this.getCluster();
		if (cluster && !cluster.hostDefaults.authorizedKeys)
			cluster.hostDefaults.authorizedKeys = [];

		if (cluster) {
			this.state = {
				cluster,
				anchorEl: null
			};
		}
	}

	onDeleteClick = () => {
		this.props.clusterDeleteRequested(this.state.cluster);
		this.props.showSnackbar({
			msg: `Cluster '${this.state.cluster.name}' deleted.`
		});
		this.props.history.push("/management");
	};

	getCluster = (): Cluster | undefined => {
		const cluster = this.props.clusterList.find((cluster: Cluster) => {
			return cluster.name === this.props.match.params.name;
		});

		if (!cluster) {
			this.props.showSnackbar({
				msg: `Unable to find cluster "${this.props.match.params.name}"`
			});
			this.props.history.push("/management");
			return;
		}

		return cluster;
	};

	componentWillReceiveProps = (newProps: Props) => {
		// console.log("componentWillRecieveProps", newProps);

		if (newProps.match.params.name !== this.state.cluster.name) {
			const cluster = ClustersApi.fetch(newProps.match.params.name);
			console.log("cluster", cluster);
			this.setState({
				cluster
			});
		}
	};

	onAddNodeClick = () => {
		// navigate to /management/nodes/new with pre-filled cluster fields in node and host config
		// this.props.history.push(
		// 	`/management/nodes/new?clusterName=${this.state.cluster.name}`
		// );
		this.props.nodeCreateWizardShow(this.state.cluster);
	};

	onMoreClick = (event: any) => {
		// isExpanded menu
		this.setState({ ...this.state, anchorEl: event.currentTarget });
	};

	onMenuClose = () => {
		this.setState({ ...this.state, anchorEl: null });
	};

	render(): React.ReactNode {
		const { classes, theme } = this.props;
		const { cluster } = this.state;

		if (!this.state) return false;

		return (
			<Card className={classes.card}>
				<CardHeader
					avatar={
						<Avatar
							style={{ backgroundColor: theme.palette.primary.main }}
							aria-label="Cluster"
						>
							<DeviceHub />
						</Avatar>
					}
					// action={
					// 	<>
					// 		<IconButton onClick={this.onMoreClick}>
					// 			<MoreVertIcon />
					// 		</IconButton>
					// 		<Menu
					// 			id="long-menu"
					// 			anchorEl={this.state.anchorEl}
					// 			isExpanded={Boolean(this.state.anchorEl)}
					// 			onClose={this.onMenuClose}
					// 		>
					// 			{/* <MenuItem onClick={this.handleClose}>Edit</MenuItem> */}
					// 			<MenuItem onClick={this.onAddNodeClick}>Add Node</MenuItem>
					// 		</Menu>
					// 	</>
					// }
					title={cluster.name}
					subheader={cluster.nodeDefaults.dbEngine}
				/>
				<Divider />
				<CardContent>
					<ClusterFormComponent
						cluster={cluster}
						readOnly={true}
						authorizedKeysSubtitle={
							"Below is the list of currently active authorized keys in this cluster."
						}
					/>
				</CardContent>
				<Divider />
				<CardActions>
					<Button
						style={{ color: theme.palette.secondary.dark }}
						size="small"
						onClick={this.onDeleteClick}
					>
						Delete
					</Button>
					<div style={{ flexGrow: 1 }} />
					<Button color="primary" size="small" onClick={this.onAddNodeClick}>
						Add node
					</Button>
				</CardActions>
			</Card>
		);
	}
}

// REDUX MAPPINGS
const mapGlobalStateToProps = (state: AppState) => ({
	clusterList: state.clusterList
});

const mapGlobalDispatchToProps = (dispatch: any) => ({
	showSnackbar: (snackbar: SnackbarActionPayload) => {
		dispatch(showSnackbar(snackbar));
	},
	clusterDeleteRequested: (cluster: Cluster) => {
		dispatch(clusterDeleteRequested(cluster));
	},
	nodeCreateWizardShow: (cluster: Cluster) => {
		dispatch(nodeCreateWizardShow(cluster));
	}
});

export default withStyles(styles, { withTheme: true })(
	connect(
		mapGlobalStateToProps,
		mapGlobalDispatchToProps
	)(ClusterManagerComponent)
);
