import {
	Avatar,
	Collapse,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Theme,
	withStyles
} from "@material-ui/core";
import { ExpandLess, ExpandMore, Layers } from "@material-ui/icons";
import { AppState } from "AppState";
import NodeItemComponent from "components/management/treeView/nodeItem/NodeItemComponent";
import { History } from "history";
import React from "react";
import { connect } from "react-redux";

const styles = (theme: Theme) => ({
	root: {
		width: "100%",
		maxWidth: 360,
		backgroundColor: theme.palette.background.paper
	},
	nested: {
		paddingLeft: theme.spacing(4)
	},
	doubleNested: {
		paddingLeft: theme.spacing(6)
	}
});

// component local state interface
interface State {
	isExpanded: boolean;
}

// PROPS
interface Props {
	history?: History;
	segment: any;
	classes: any;
}

interface DispatchProps {}

type SegmentItemComponentProps = Props & DispatchProps;

// COMPONENT
class SegmentItemComponent extends React.Component<
	SegmentItemComponentProps,
	State
> {
	constructor(props: SegmentItemComponentProps) {
		super(props);

		this.state = {
			isExpanded: true
		};
	}

	handleItemClick = () => {
		if (!this.state.isExpanded)
			this.setState(state => ({ ...state, isExpanded: true }));
	};

	handleExpandClick = () => {
		this.setState(state => ({ isExpanded: !state.isExpanded }));
	};

	render(): React.ReactNode {
		return (
			<>
				<ListItem
					button
					onClick={this.handleItemClick}
					className={this.props.classes.nested}
				>
					<ListItemAvatar>
						<Avatar>
							<Layers />
						</Avatar>
					</ListItemAvatar>
					<ListItemText primary={this.props.segment.name} />
					{this.state.isExpanded ? (
						<ExpandLess onClick={this.handleExpandClick} />
					) : (
						<ExpandMore onClick={this.handleExpandClick} />
					)}
				</ListItem>
				<Collapse in={this.state.isExpanded} timeout="auto" unmountOnExit>
					<List disablePadding>
						{this.props.segment.nodes.map((node: any) => {
							return <NodeItemComponent key={node.id} node={node} />;
						})}
					</List>
				</Collapse>
			</>
		);
	}
}

// REDUX MAPPINGS
const mapGlobalStateToProps = (state: AppState) => {
	return {};
};

const mapGlobalDispatchToProps = (dispatch: any) => {
	return {};
};

export default withStyles(styles)(
	connect(
		mapGlobalStateToProps,
		mapGlobalDispatchToProps
	)(SegmentItemComponent)
);
