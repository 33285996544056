import { HostDefaults } from "components/management/host/types";
import { NodeDefaults } from "components/management/node/types";

export interface Cluster {
	id?: number;
	name: string;
	owner: string;
	first?: boolean;
	nodes: string[];
	hosts: string[];
	nodeDefaults: NodeDefaults;
	hostDefaults: HostDefaults;
}

export enum CLUSTER_ACTION {
	LIST_FETCH_REQUESTED = "CLUSTER/LIST_FETCH_REQUESTED",
	LIST_FETCH_SUCCEDED = "CLUSTER/LIST_FETCH_SUCCEDED",
	// CREATE_REQUESTED = "CLUSTER/CREATE_REQUESTED",
	// CREATE_SUCCEDED = "CLUSTER/CREATE_SUCCEDED",
	DELETE_REQUESTED = "CLUSTER/DELETE_REQUESTED",
	DELETE_SUCCEDED = "CLUSTER/DELETE_SUCCEDED"
}

export interface ClusterListFetchRequestedAction {
	type: CLUSTER_ACTION.LIST_FETCH_REQUESTED;
}

export interface ClusterListFetchSuccededAction {
	type: CLUSTER_ACTION.LIST_FETCH_SUCCEDED;
	payload: {
		clusterList: Cluster[];
	};
}

// export interface ClusterCreateRequestedAction {
// 	type: CLUSTER_ACTION.CREATE_REQUESTED;
// 	payload: {
// 		cluster: Cluster;
// 	};
// }
//
// export interface ClusterCreateSuccededAction {
// 	type: CLUSTER_ACTION.CREATE_SUCCEDED;
// 	payload: {
// 		cluster: Cluster;
// 	};
// }

export interface ClusterDeleteRequestedAction {
	type: CLUSTER_ACTION.DELETE_REQUESTED;
	payload: {
		cluster: Cluster;
	};
}

export interface ClusterDeleteSuccededAction {
	type: CLUSTER_ACTION.DELETE_SUCCEDED;
	payload: {
		clusterName: string;
	};
}

export type ClusterAction =
	| ClusterListFetchRequestedAction
	| ClusterListFetchSuccededAction
	// | ClusterCreateRequestedAction
	// | ClusterCreateSuccededAction
	| ClusterDeleteRequestedAction
	| ClusterDeleteSuccededAction;
