import { Theme, createStyles } from "@material-ui/core";

export const styles = (theme: Theme) =>
	createStyles({
		card: {
			width: "100%",
			[theme.breakpoints.up("sm")]: {
				width: 700
			}
		}
		// title: {
		// 	fontSize: 14
		// },
		// pos: {
		// 	marginBottom: 12
		// }
	});
