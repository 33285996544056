import { HOST_SYSTEM, HOST_TYPE } from "components/management/host/types";

class HostUtils {
	static getHostSystemEnum = (value: string): HOST_SYSTEM => {
		switch (value) {
			case HOST_SYSTEM.CENTOS_7:
				return HOST_SYSTEM.CENTOS_7;
			case HOST_SYSTEM.UBUNTU_18:
				return HOST_SYSTEM.UBUNTU_18;
			default:
				return HOST_SYSTEM.CENTOS_7;
		}
	};

	static getHostTypeEnum = (value: string): HOST_TYPE => {
		switch (value) {
			case HOST_TYPE.LOCAL_LXD:
				return HOST_TYPE.LOCAL_LXD;
			default:
				return HOST_TYPE.LOCAL_LXD;
		}
	};

}

export default HostUtils;