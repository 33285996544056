import { Host } from "components/management/host/types";
import { Node } from "components/management/node/types";
import { Job, JOB_STATUS } from "modules/jobs/types";
import { Cluster } from "components/management/cluster/types";

export interface NodeCreateWizardState {
	isOpen: boolean;
	activeStep: number;
	cluster: Cluster;
	node: Node;
	host: Host;
	isCreated: boolean;
	hasErrorHappened: boolean;
	errorMessage: string;
	progressPercent: number;
	progressMessage: string;
	log: any[];
	startNodeAfterDeployment: boolean;
	hostDeleteErrorMessage: string;
	isHostDeployed: boolean;
	isHostDeletionInProgress: boolean;
	hasErrorHappenedDuringHostDeletion: boolean;
	hostDeployJob: {
		id: number;
		status: JOB_STATUS;
	};
	hostDeleteJob: {
		id: number;
		status: JOB_STATUS;
	};
	nodeInstallJob: {
		id: number;
		status: JOB_STATUS;
	};
}

export enum NODE_CREATE_WIZARD_ACTION {
	SHOW = "NODE_CREATE_WIZARD/SHOW",
	HIDE = "NODE_CREATE_WIZARD/HIDE",
	GO_TO_DATA_ENTRY = "NODE_CREATE_WIZARD/GO_TO_DATA_ENTRY",
	GO_TO_DEPLOY_VIEW = "NODE_CREATE_WIZARD/GO_TO_DEPLOY_VIEW",
	GO_TO_FINISH = "NODE_CREATE_WIZARD/GO_TO_FINISH",
	SET_NODE = "NODE_CREATE_WIZARD/SET_NODE",
	SET_HOST = "NODE_CREATE_WIZARD/SET_HOST",
	APPEND_LOG_DATA = "NODE_CREATE_WIZARD/APPEND_LOG_DATA",
	TOGGLE_AUTO_START_NODE = "NODE_CREATE_WIZARD/TOGGLE_AUTO_START_NODE",
	DEPLOY_HOST_REQUESTED = "NODE_CREATE_WIZARD/DEPLOY_HOST_REQUESTED",
	DEPLOY_HOST_JOB_STARTED = "NODE_CREATE_WIZARD/DEPLOY_HOST_JOB_STARTED",
	DEPLOY_HOST_SUCCEDED = "NODE_CREATE_WIZARD/DEPLOY_HOST_SUCCEDED",
	DEPLOY_HOST_FAILED = "NODE_CREATE_WIZARD/DEPLOY_HOST_FAILED",
	DELETE_HOST_REQUESTED = "NODE_CREATE_WIZARD/DELETE_HOST_REQUESTED",
	DELETE_HOST_JOB_STARTED = "NODE_CREATE_WIZARD/DELETE_HOST_JOB_STARTED",
	DELETE_HOST_SUCCEDDED = "NODE_CREATE_WIZARD/DELETE_HOST_SUCCEDED",
	DELETE_HOST_FAILED = "NODE_CREATE_WIZARD/DELETE_HOST_FAILED",
	INSTALL_NODE_REQUESTED = "NODE_CREATE_WIZARD/NODE_INSTALL_REQUESTED",
	INSTALL_NODE_JOB_STARTED = "NODE_CREATE_WIZARD/NODE_INSTALL_JOB_STARTED",
	INSTALL_NODE_SUCCEDED = "NODE_CREATE_WIZARD/NODE_INSTALL_SUCCEDED",
	INSTALL_NODE_FAILED = "NODE_CREATE_WIZARD/NODE_INSTALL_FAILED",
	CREATE_ANOTHER_NODE = "NODE_CREATE_WIZARD/CREATE_ANOTHER_NODE"
}

export interface NodeCreateWizardShowAction {
	type: NODE_CREATE_WIZARD_ACTION.SHOW;
	payload: {
		cluster: Cluster;
	};
}

export interface NodeCreateWizardHideAction {
	type: NODE_CREATE_WIZARD_ACTION.HIDE;
}

export interface NodeCreateWizardGoToDataEntryAction {
	type: NODE_CREATE_WIZARD_ACTION.GO_TO_DATA_ENTRY;
}

export interface NodeCreateWizardGoToDeployViewAction {
	type: NODE_CREATE_WIZARD_ACTION.GO_TO_DEPLOY_VIEW;
}

export interface NodeCreateWizardGoToFinishAction {
	type: NODE_CREATE_WIZARD_ACTION.GO_TO_FINISH;
}

export interface NodeCreateWizardSetNodeAction {
	type: NODE_CREATE_WIZARD_ACTION.SET_NODE;
	payload: {
		node: Node;
	};
}

export interface NodeCreateWizardSetHostAction {
	type: NODE_CREATE_WIZARD_ACTION.SET_HOST;
	payload: {
		host: Host;
	};
}

export interface NodeCreateWizardAppendLogDataAction {
	type: NODE_CREATE_WIZARD_ACTION.APPEND_LOG_DATA;
	payload: {
		logData: any;
	};
}

export interface NodeCreateWizardToggleAutoStartNodeAction {
	type: NODE_CREATE_WIZARD_ACTION.TOGGLE_AUTO_START_NODE;
}

export interface NodeCreateWizardDeployHostRequestedAction {
	type: NODE_CREATE_WIZARD_ACTION.DEPLOY_HOST_REQUESTED;
	payload: {
		host: Host;
	};
}

export interface NodeCreateWizardDeployHostJobStartedAction {
	type: NODE_CREATE_WIZARD_ACTION.DEPLOY_HOST_JOB_STARTED;
	payload: {
		job: Job;
	};
}

export interface NodeCreateWizardDeployHostSuccededAction {
	type: NODE_CREATE_WIZARD_ACTION.DEPLOY_HOST_SUCCEDED;
}

export interface NodeCreateWizardDeployHostFailedAction {
	type: NODE_CREATE_WIZARD_ACTION.DEPLOY_HOST_FAILED;
	payload: {
		reason: string;
	};
}

export interface NodeCreateWizardDeleteHostRequestedAction {
	type: NODE_CREATE_WIZARD_ACTION.DELETE_HOST_REQUESTED;
	payload: {
		host: Host;
	};
}

export interface NodeCreateWizardDeleteHostJobStartedAction {
	type: NODE_CREATE_WIZARD_ACTION.DELETE_HOST_JOB_STARTED;
	payload: {
		job: Job;
	};
}

export interface NodeCreateWizardDeleteHostSuccededAction {
	type: NODE_CREATE_WIZARD_ACTION.DELETE_HOST_SUCCEDDED;
}

export interface NodeCreateWizardDeleteHostFailedAction {
	type: NODE_CREATE_WIZARD_ACTION.DELETE_HOST_FAILED;
	payload: {
		reason: string;
	};
}

export interface NodeCreateWizardInstallNodeRequestedAction {
	type: NODE_CREATE_WIZARD_ACTION.INSTALL_NODE_REQUESTED;
	payload: {
		node: Node;
	};
}

export interface NodeCreateWizardInstallNodeJobStartedAction {
	type: NODE_CREATE_WIZARD_ACTION.INSTALL_NODE_JOB_STARTED;
	payload: {
		job: Job;
	};
}

export interface NodeCreateWizardInstallNodeSuccededAction {
	type: NODE_CREATE_WIZARD_ACTION.INSTALL_NODE_SUCCEDED;
}

export interface NodeCreateWizardInstallNodeFailedAction {
	type: NODE_CREATE_WIZARD_ACTION.INSTALL_NODE_FAILED;
	payload: {
		reason: string;
	};
}

export interface NodeCreateWizardCreateAnotherNodeAction {
	type: NODE_CREATE_WIZARD_ACTION.CREATE_ANOTHER_NODE;
}

export type NodeCreateWizardAction =
	| NodeCreateWizardShowAction
	| NodeCreateWizardHideAction
	| NodeCreateWizardInstallNodeFailedAction
	| NodeCreateWizardSetNodeAction
	| NodeCreateWizardSetHostAction
	| NodeCreateWizardAppendLogDataAction
	| NodeCreateWizardGoToDataEntryAction
	| NodeCreateWizardGoToDeployViewAction
	| NodeCreateWizardGoToFinishAction
	| NodeCreateWizardDeployHostRequestedAction
	| NodeCreateWizardDeployHostJobStartedAction
	| NodeCreateWizardDeployHostSuccededAction
	| NodeCreateWizardDeployHostFailedAction
	| NodeCreateWizardInstallNodeRequestedAction
	| NodeCreateWizardInstallNodeJobStartedAction
	| NodeCreateWizardInstallNodeSuccededAction
	| NodeCreateWizardToggleAutoStartNodeAction
	| NodeCreateWizardCreateAnotherNodeAction
	| NodeCreateWizardDeleteHostRequestedAction
	| NodeCreateWizardDeleteHostJobStartedAction
	| NodeCreateWizardDeleteHostSuccededAction
	| NodeCreateWizardDeleteHostFailedAction;
