export enum JOB_STATUS {
	NONE = -2,
	MISSING = -1,
	NEW = 0,
	RUNNING = 1,
	SUCCESS = 2,
	FAILURE = 3,
	ABORTED = 4
}

export interface Job {
	id: number;
	status: JOB_STATUS;
}

export enum JOB_ACTION {
	FETCH_REQUESTED = "JOB/FETCH_REQUESTED",
	FETCH_SUCCEDED = "JOB/FETCH_SUCCEDED"
}

export interface JobFetchRequestedAction {
	type: JOB_ACTION.FETCH_REQUESTED;
	payload: {
		jobId: number;
	};
}

export interface JobFetchSuccededAction {
	type: JOB_ACTION.FETCH_SUCCEDED;
	payload: {
		job: Job;
	};
}

export type JobAction = JobFetchRequestedAction | JobFetchSuccededAction;
