import Axios, { AxiosResponse } from "axios";
import { Host } from "components/management/host/types";
import AppEnvironment from "modules/appEnvironment";

let hosts: Host[] = [];

class HostsApi {
	static async fetchList(): Promise<AxiosResponse> {
		const response = await Axios.get(
			`${AppEnvironment.getApiHostAddress()}/${AppEnvironment.getApiVersion()}/hosts`
		);
		hosts = response.data;
		return response;
	}

	static fetch(name: string): any {
		return hosts.find((host: Host) => {
			return host.name === name;
		});
	}

	static async create(host: Host): Promise<AxiosResponse> {
		return await Axios.post(
			`${AppEnvironment.getApiHostAddress()}/${AppEnvironment.getApiVersion()}/hosts`,
			host
		);
	}

	static async delete(host: Host): Promise<AxiosResponse> {
		return await Axios.delete(`${AppEnvironment.getApiHostAddress()}/${AppEnvironment.getApiVersion()}/hosts/${host.name}?with_node=1`);
	}
}

export default HostsApi;
