import {
	HOST_ACTION,
	HostAction,
	Host
} from "components/management/host/types";

const initialState: Host[] = [];

export function hostListReducer(
	state: Host[] = initialState,
	action: HostAction
): Host[] {
	switch (action.type) {
		case HOST_ACTION.LIST_FETCH_SUCCEDED:
			return action.payload.hostList;
		default:
			return state;
	}
}
