import { call, put, takeLatest } from "redux-saga/effects";
import { USER_ACTION } from "components/users/types";
import UsersApi from "modules/api/UsersApi";
import { userListFetchSucceeded } from "components/users/actions";

function* fetchUserList() {
	console.log("fetchUserList saga");

	try {
		const response = yield call(UsersApi.fetchList);
		console.log("saga user list fetched", response);

		yield put(userListFetchSucceeded(response.data));
	} catch (e) {
		console.error(e);
	}
}

function* UserSideEffects() {
	yield takeLatest(USER_ACTION.LIST_FETCH_REQUESTED, fetchUserList);
}

export default UserSideEffects;
