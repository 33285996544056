export interface Host {
	id?: number;
	name: string;
	node?: string;
	cluster: string;
	type: HOST_TYPE;
	system: HOST_SYSTEM;
	deploymentStatus?: string;
	privateKey: string;
	privateKeyPassword?: string;
	ssh?: {
		address: string;
		port: string;
	};
	db?: {
		address: string;
		port: string;
	};
	authorizedKeys: string[];
}

export interface HostDefaults {
	type: HOST_TYPE;
	system: HOST_SYSTEM;
	privateKey: string;
	privateKeyPassword: string;
	authorizedKeys: string[];
}

export enum HOST_TYPE {
	LOCAL_LXD = "locallxd"
}

export enum HOST_SYSTEM {
	CENTOS_7 = "centos:7",
	UBUNTU_18 = "ubuntu:18.04"
}

// redux actions
export enum HOST_ACTION {
	LIST_FETCH_REQUESTED = "HOST/LIST_FETCH_REQUESTED",
	LIST_FETCH_SUCCEDED = "HOST/LIST_FETCH_SUCCEDED"
}

export interface HostListFetchRequestedAction {
	type: HOST_ACTION.LIST_FETCH_REQUESTED;
}

export interface HostListFetchSuccededAction {
	type: HOST_ACTION.LIST_FETCH_SUCCEDED;
	payload: {
		hostList: Host[];
	};
}

export type HostAction =
	| HostListFetchRequestedAction
	| HostListFetchSuccededAction
