import React from "react";
import "./AppInfoComponent.sass";
import moment from "moment";

interface LocalProps {}

type Props = LocalProps;

class AppInfo extends React.Component<Props> {
	render() {
		return (
			<div
				className="app-info"
				title={
					process.env.REACT_APP_BUILD_TIMESTAMP &&
					`Built: ${moment
						.unix(parseInt(process.env.REACT_APP_BUILD_TIMESTAMP))
						.format("LLL")}`
				}
			>
				{process.env.REACT_APP_BUILD_TIMESTAMP &&
					`Built: ${moment
						.unix(parseInt(process.env.REACT_APP_BUILD_TIMESTAMP))
						.fromNow()}`}
			</div>
		);
	}
}

export default AppInfo;
