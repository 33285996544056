import { clusterListFetchRequested } from "components/management/cluster/actions";
import ClustersApi from "modules/api/ClustersApi";
import { call, put, takeEvery } from "redux-saga/effects";
import {
	CLUSTER_CREATE_WIZARD_ACTION,
	ClusterCreateWizardCreateRequestedAction
} from "components/management/cluster/clusterCreateWizard/types";
import {
	clusterCreateWizardCreateFailed,
	clusterCreateWizardCreateSucceded
} from "components/management/cluster/clusterCreateWizard/actions";

function* createCluster(action: ClusterCreateWizardCreateRequestedAction) {
	try {
		yield call(ClustersApi.create, action.payload.cluster);
		yield put(clusterCreateWizardCreateSucceded());
		yield put(clusterListFetchRequested());
	} catch (e) {
		yield put(
			clusterCreateWizardCreateFailed(`Cluster error: ${e.response.data}`)
		);
	}
}

function* ClusterCreateWizardSideEffects() {
	yield takeEvery(CLUSTER_CREATE_WIZARD_ACTION.CREATE_REQUESTED, createCluster);
}

export default ClusterCreateWizardSideEffects;
