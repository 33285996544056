import {
	CLUSTER_CREATE_WIZARD_ACTION,
	ClusterCreateWizardAction,
	ClusterCreateWizardState
} from "components/management/cluster/clusterCreateWizard/types";
import { HOST_SYSTEM, HOST_TYPE } from "components/management/host/types";
import { NODE_DB_ENGINE } from "components/management/node/types";

const initialState: ClusterCreateWizardState = {
	activeStep: 0,
	cluster: {
		name: "",
		owner: "",
		hostDefaults: {
			type: HOST_TYPE.LOCAL_LXD,
			system: HOST_SYSTEM.CENTOS_7,
			privateKey: "",
			privateKeyPassword: "",
			authorizedKeys: []
		},
		nodeDefaults: {
			dbEngine: NODE_DB_ENGINE.MARIADB_10_3
		},
		nodes: [],
		hosts: []
	},
	errorMessage: "",
	hasErrorHappened: false,
	isCreating: false,
	isOpen: false
};

export function clusterCreateWizardReducer(
	state: ClusterCreateWizardState = initialState,
	action: ClusterCreateWizardAction
): ClusterCreateWizardState {
	switch (action.type) {
		case CLUSTER_CREATE_WIZARD_ACTION.SHOW:
			return { ...initialState, isOpen: true };
		case CLUSTER_CREATE_WIZARD_ACTION.HIDE:
			return { ...state, isOpen: false };
		case CLUSTER_CREATE_WIZARD_ACTION.SET_CLUSTER:
			return { ...state, cluster: action.payload.cluster };
		case CLUSTER_CREATE_WIZARD_ACTION.CREATE_REQUESTED:
			return {
				...state,
				isCreating: true,
				hasErrorHappened: false,
				errorMessage: ""
			};
		case CLUSTER_CREATE_WIZARD_ACTION.CREATE_SUCCEDED:
			return { ...state, isCreating: false, activeStep: 1 };
		case CLUSTER_CREATE_WIZARD_ACTION.CREATE_FAILED:
			return {
				...state,
				isCreating: false,
				hasErrorHappened: true,
				errorMessage: action.payload.reason
			};
		default:
			return state;
	}
}
