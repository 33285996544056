import * as React from "react";
import { ChangeEvent, FormEvent } from "react";
import {
	Button,
	CircularProgress,
	Divider,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Tooltip,
	Typography
} from "@material-ui/core";
import { Node, NODE_DB_ENGINE } from "components/management/node/types";
import { Host, HOST_SYSTEM, HOST_TYPE } from "components/management/host/types";
import PrivateKeyEditor from "components/sharedComponents/privateKeyEditor/PrivateKeyEditorComponent";
import AuthorizedKeysEditor from "components/sharedComponents/authorizedKeysEditor/AuthorizedKeysEditorComponent";
import { Cluster } from "components/management/cluster/types";
import { connect } from "react-redux";
import { AppState } from "../../../AppState";
import { RouteComponentProps, StaticContext, withRouter } from "react-router";
import NodeUtils from "components/management/node/utils";
import HostUtils from "components/management/host/utils";

interface LocalState {
	cluster: Cluster;
	node: Node;
	host: Host;
	formValidation: {
		nodeName: {
			invalid: boolean;
			message: string;
		};
		hostName: {
			invalid: boolean;
			message: string;
		};
	};
}

interface LocalProps {
	cluster: Cluster;
	node: Node;
	host: Host;
	readOnly?: boolean;
	onSubmit: (node: Node, host: Host) => void;
	// used during host deployment
	isHostDeployed?: boolean;
	isHostDeletionInProgress?: boolean;
	onHostDelete?: () => void;
	hasErrorHappenedDuringHostDeletion?: boolean;
	hostDeleteErrorMessage?: string;
}

type Props = LocalProps & RouteComponentProps<any, StaticContext, any>;

class NodeFormComponent extends React.Component<Props, LocalState> {
	constructor(props: Props) {
		super(props);

		this.state = {
			cluster: props.cluster,
			node: props.node,
			host: props.host,
			formValidation: {
				nodeName: {
					invalid: false,
					message: ""
				},
				hostName: {
					invalid: false,
					message: ""
				}
			}
		};
	}

	onSubmit = (e: FormEvent) => {
		e.preventDefault();
		console.log("onSubmit", e);
		if (this.props.readOnly !== false && this.props.onSubmit) {
			this.props.onSubmit(this.state.node, this.state.host);
		}
	};

	render() {
		const { cluster, node, host } = this.state;
		const {
			isHostDeployed,
			isHostDeletionInProgress,
			hasErrorHappenedDuringHostDeletion,
			hostDeleteErrorMessage
		} = this.props;

		return (
			<>
				<form id="nodeForm" onSubmit={this.onSubmit}>
					<Grid container direction="column" spacing={2}>
						<Grid container item direction="column">
							{/*<Grid item>*/}
							{/*	<Typography variant="subtitle2">*/}
							{/*		Please enter node and host data:*/}
							{/*	</Typography>*/}
							{/*</Grid>*/}
							<Grid container item direction="row" spacing={2}>
								<Grid item sm={6} xs={12}>
									<FormControl
										onInvalid={(e: FormEvent): void => {
											e.preventDefault();
											const form = e.target as HTMLFormElement;

											this.setState((state: LocalState) => ({
												...state,
												formValidation: {
													...state.formValidation,
													nodeName: {
														...state.formValidation.nodeName,
														invalid: true,
														message: form.validationMessage
													}
												}
											}));
										}}
										error={this.state.formValidation.nodeName.invalid}
										fullWidth={true}
										required
									>
										<TextField
											error={this.state.formValidation.nodeName.invalid}
											margin="dense"
											required
											label="Node name"
											autoFocus={true}
											autoComplete="off"
											value={node.name}
											helperText={this.state.formValidation.nodeName.message}
											onChange={(e: ChangeEvent) => {
												const field = e.target as HTMLFormElement;

												this.setState(
													(state: LocalState): LocalState => ({
														...state,
														node: {
															...state.node,
															name: field.value
														}
													})
												);

												if (field.checkValidity()) {
													this.setState((state: LocalState) => ({
														...state,
														formValidation: {
															...state.formValidation,
															nodeName: {
																...state.formValidation.nodeName,
																invalid: false,
																message: ""
															}
														}
													}));
												} else {
													this.setState((state: LocalState) => ({
														...state,
														formValidation: {
															...state.formValidation,
															nodeName: {
																...state.formValidation.nodeName,
																invalid: true,
																message: field.validationMessage
															}
														}
													}));
												}
											}}
										/>
									</FormControl>
								</Grid>
								<Grid item sm={6} xs={12}>
									<FormControl fullWidth={true} margin="dense">
										<InputLabel htmlFor="node-db-engine">
											Node DB Engine
										</InputLabel>
										<Select
											fullWidth={true}
											value={node.dbEngine}
											onChange={e => {
												const value = e.target.value as string;
												this.setState(
													(state: LocalState): LocalState => ({
														...state,
														node: {
															...state.node,
															dbEngine: NodeUtils.getNodeDbEngineEnum(value)
														}
													})
												);
											}}
											inputProps={{
												id: "node-db-engine"
											}}
										>
											{Object.values(NODE_DB_ENGINE).map(
												(db_engine: string) => (
													<MenuItem key={db_engine} value={db_engine}>
														{db_engine}
													</MenuItem>
												)
											)}
										</Select>
									</FormControl>
								</Grid>
							</Grid>
							<Grid container item direction="row" spacing={2}>
								<Grid item sm={6} xs={12}>
									<TextField
										fullWidth={true}
										margin="dense"
										label="Node segment"
										disabled
										value={node.segment}
									/>
								</Grid>
								{/*todo: put this inside wizard*/}
								{/*<Grid item sm={6} xs={12}>*/}
								{/*	<FormControlLabel*/}
								{/*		control={*/}
								{/*			<Checkbox*/}
								{/*				checked={*/}
								{/*					this.props.nodeCreateWizard.startNodeAfterDeployment*/}
								{/*				}*/}
								{/*				onChange={(e: ChangeEvent) => {*/}
								{/*					this.props.nodeCreateWizardToggleAutoStartNode();*/}
								{/*				}}*/}
								{/*				value="checkedB"*/}
								{/*				color="primary"*/}
								{/*			/>*/}
								{/*		}*/}
								{/*		label="Automatically start node after deployment"*/}
								{/*	/>*/}
								{/*</Grid>*/}
							</Grid>
						</Grid>
						<Grid container item direction="column">
							<Grid item container direction="row" alignContent={"center"}>
								<Typography variant="subtitle1">Host</Typography>
								{isHostDeployed && (
									<>
										<Tooltip
											title="It looks like something went wrong during the deployment, but host still got installed. If you wish, you can keep the host and just install node on it, or you can delete it and create a new one with different parameters."
											placement="top"
										>
											<Typography variant="subtitle1" color="secondary">
												&nbsp;(already deployed)
											</Typography>
										</Tooltip>
										<div style={{ flexGrow: 1 }} />
										<Button
											size="small"
											disabled={isHostDeletionInProgress}
											color="secondary"
											onClick={(): void => {
												this.props.onHostDelete && this.props.onHostDelete();
											}}
										>
											{isHostDeletionInProgress && (
												<CircularProgress size={20} />
											)}
											Delete host
										</Button>
									</>
								)}
							</Grid>
							<Divider />
							{hasErrorHappenedDuringHostDeletion && (
								<Grid
									item
									container
									direction="row"
									spacing={1}
									alignItems="center"
								>
									<Grid item>
										<Typography variant="subtitle2" color="error">
											{hostDeleteErrorMessage}
										</Typography>
									</Grid>
								</Grid>
							)}
							<Grid container direction="row" spacing={2}>
								<Grid item sm={6} xs={12}>
									<FormControl
										onInvalid={(e: FormEvent): void => {
											e.preventDefault();
											const form = e.target as HTMLFormElement;

											this.setState((state: LocalState) => ({
												...state,
												formValidation: {
													...state.formValidation,
													hostName: {
														...state.formValidation.hostName,
														invalid: true,
														message: form.validationMessage
													}
												}
											}));
										}}
										error={this.state.formValidation.hostName.invalid}
										fullWidth={true}
										required
									>
										<TextField
											error={this.state.formValidation.hostName.invalid}
											margin="dense"
											required
											disabled={isHostDeployed}
											label="Host name"
											autoComplete="off"
											inputProps={{
												maxLength: 50
											}}
											helperText={this.state.formValidation.hostName.message}
											value={host.name}
											onChange={(e: ChangeEvent) => {
												const field = e.target as HTMLFormElement;

												this.setState(
													(state: LocalState): LocalState => ({
														...state,
														host: {
															...state.host,
															name: field.value
														},
														node: {
															...state.node,
															host: field.value
														}
													})
												);

												if (field.checkValidity()) {
													this.setState((state: LocalState) => ({
														...state,
														formValidation: {
															...state.formValidation,
															hostName: {
																...state.formValidation.hostName,
																invalid: false,
																message: ""
															}
														}
													}));
												} else {
													this.setState((state: LocalState) => ({
														...state,
														formValidation: {
															...state.formValidation,
															hostName: {
																...state.formValidation.hostName,
																invalid: true,
																message: field.validationMessage
															}
														}
													}));
												}
											}}
										/>
									</FormControl>
								</Grid>
								<Grid item sm={6} xs={12}>
									<FormControl fullWidth={true} margin="dense">
										<InputLabel htmlFor="host-system">Host system</InputLabel>
										<Select
											disabled={isHostDeployed}
											fullWidth={true}
											value={host.system}
											onChange={e => {
												const value = e.target.value as string;
												this.setState(
													(state: LocalState): LocalState => ({
														...state,
														host: {
															...state.host,
															system: HostUtils.getHostSystemEnum(value)
														}
													})
												);
											}}
											inputProps={{
												id: "host-system"
											}}
										>
											{Object.values(HOST_SYSTEM).map((system: string) => (
												<MenuItem key={system} value={system}>
													{system}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</Grid>
							</Grid>
							<Grid container direction="row" spacing={2}>
								<Grid item sm={6} xs={12}>
									<FormControl fullWidth={true} margin="dense">
										<InputLabel htmlFor="host-type">Host type</InputLabel>
										<Select
											disabled={isHostDeployed}
											fullWidth={true}
											value={host.type}
											onChange={e => {
												const value = e.target.value as string;
												this.setState(
													(state: LocalState): LocalState => ({
														...state,
														host: {
															...state.host,
															type: HostUtils.getHostTypeEnum(value)
														}
													})
												);
											}}
											inputProps={{
												id: "host-type"
											}}
										>
											{Object.values(HOST_TYPE).map((type: string) => (
												<MenuItem key={type} value={type}>
													{type}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</Grid>
							</Grid>
							<Grid container direction="row" spacing={2}>
								<Grid item sm={6} xs={12}>
									<PrivateKeyEditor
										readOnly={isHostDeployed || false}
										subtitleText={
											"Enter private key to override the key inherited from cluster."
										}
										inheritedPrivateKey={cluster.hostDefaults.privateKey}
										privateKey={host.privateKey}
										onSubmit={(privateKey: string) => {
											this.setState(
												(state: LocalState): LocalState => ({
													...state,
													host: {
														...state.host,
														privateKey
													}
												})
											);
										}}
									/>
								</Grid>
								<Grid item sm={6} xs={12}>
									<AuthorizedKeysEditor
										subtitleText={
											"You can add custom authorized keys in addition to the keys inherited from cluster."
										}
										authorizedKeys={host.authorizedKeys}
										inheritedAuthorizedKeys={
											cluster.hostDefaults.authorizedKeys
										}
										readOnly={isHostDeployed || false}
										onAdd={(publicKey: string): void => {
											this.setState(
												(state: LocalState): LocalState => ({
													...state,
													host: {
														...state.host,
														authorizedKeys: [
															...state.host.authorizedKeys,
															publicKey
														]
													}
												})
											);
										}}
										onRemove={(deletedKey: string, index: number): void => {
											this.setState(
												(state: LocalState): LocalState => {
													const filteredKeys = state.host.authorizedKeys.filter(
														(publicKey: string) => publicKey !== deletedKey
													);

													return {
														...state,
														host: {
															...state.host,
															authorizedKeys: filteredKeys
														}
													};
												}
											);
										}}
									/>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</form>
			</>
		);
	}
}

// REDUX MAPPINGS
const mapGlobalStateToProps = (state: AppState) => ({});

const mapGlobalDispatchToProps = (dispatch: any) => ({
	// showSnackbar: (snackbar: SnackbarActionPayload) => {
	// 	dispatch(showSnackbar(snackbar));
	// },
	// clusterCreateWizardCreateRequested: (cluster: Cluster) => {
	// 	dispatch(clusterCreateWizardCreateRequested(cluster));
	// },
	// clusterCreateWizardHide: () => {
	// 	dispatch(clusterCreateWizardHide());
	// },
	// clusterCreateWizardSetCluster: (cluster: Cluster) => {
	// 	dispatch(clusterCreateWizardSetCluster(cluster));
	// },
	// nodeCreateWizardShow: (cluster: Cluster) => {
	// 	dispatch(nodeCreateWizardShow(cluster));
	// }
});

export default withRouter(
	connect(
		mapGlobalStateToProps,
		mapGlobalDispatchToProps
	)(NodeFormComponent)
);
