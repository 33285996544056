import { JOB_ACTION, Job } from "modules/jobs/types";

export const jobFetchRequested = (jobId: number) => ({
	type: JOB_ACTION.FETCH_REQUESTED,
	payload: {
		jobId
	}
});

export const jobFetchSucceded = (job: Job) => ({
	type: JOB_ACTION.FETCH_SUCCEDED,
	payload: {
		job
	}
});
