export interface User {
	id?: number;
	name: string;
	role: number;
}

export enum USER_ACTION {
	LIST_FETCH_REQUESTED = "USER/LIST_FETCH_REQUESTED",
	LIST_FETCH_SUCCEEDED = "USER/LIST_FETCH_SUCCEEDED"
}

export interface UserListFetchRequestedAction {
	type: USER_ACTION.LIST_FETCH_REQUESTED;
}

export interface UserListFetchSucceededAction {
	type: USER_ACTION.LIST_FETCH_SUCCEEDED;
	payload: {
		userList: User[];
	};
}

export type UserAction =
	| UserListFetchRequestedAction
	| UserListFetchSucceededAction;
