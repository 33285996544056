import Axios, { AxiosResponse } from "axios";
import AppEnvironment from "modules/appEnvironment";

class UsersApi {
	static async fetchList(): Promise<AxiosResponse> {
		return await Axios.get(
			`${AppEnvironment.getApiHostAddress()}/${AppEnvironment.getApiVersion()}/users`
		);
	}
}

export default UsersApi;
