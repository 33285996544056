import {
	PRELOADER_ACTION,
	PreloadFailedAction,
	PreloadRequestedAction,
	PreloadSuccededAction
} from "modules/preloader/types";

export const preloadRequested = (): PreloadRequestedAction => ({
	type: PRELOADER_ACTION.PRELOAD_REQUESTED
});

export const preloadSucceded = (): PreloadSuccededAction => ({
	type: PRELOADER_ACTION.PRELOAD_SUCCEDED
});

export const preloadFailed = (reason: string): PreloadFailedAction => ({
	type: PRELOADER_ACTION.PRELOAD_FAILED,
	payload: {
		reason
	}
});