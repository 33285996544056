import JobsApi from "modules/api/JobsApi";
import { JobFetchRequestedAction, JOB_ACTION } from "modules/jobs/types";
import { call, put, takeLatest } from "redux-saga/effects";
import { jobFetchSucceded } from "modules/jobs/actions";

function* fetchJob(action: JobFetchRequestedAction) {
	const response = yield call(JobsApi.fetch, action.payload.jobId);
	yield put(jobFetchSucceded(response.data));
}

function* JobSideEffects() {
	yield takeLatest(JOB_ACTION.FETCH_REQUESTED, fetchJob);
}

export default JobSideEffects;
