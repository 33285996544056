import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { History } from "history";
import Auth from "modules/auth/Auth";
import { extendSessionDuration, endSession } from "modules/userSession/actions";
import { UserSessionState } from "modules/userSession/types";
import moment from "moment";
import { Store } from "redux";
import { showSnackbar } from "components/sharedComponents/snackbar/actionCreators";

// list of urls that do not require access token
const ACCESS_TOKEN_EXCLUDED_URLS = [
	"/jwt/signin",
	"/jwt/refresh",
	"/jwt/websocket"
];

let tokenRefreshInProgress = false;
let tokenRefreshPromise: Promise<{}>;
let history: History;

export const initializeInterceptors = (store: Store, h: History): void => {
	history = h;
	// request interceptors
	// IMPORTANT: the inteception order is the opposite of initialization order
	// (the last initialized interceptor will be the first one to handle the request)
	initializeAuthenticationHeaderHandler();
	initializeClientSideAccessTokenVerification(store);

	// response interceptors
	initialize401ResponseHandler(store);
};

const initializeAuthenticationHeaderHandler = (): void => {
	axios.interceptors.request.use(
		async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
			if (config.url && isAccessTokenRequired(config.url)) {
				config.headers["Authorization"] = `Bearer ${Auth.getHttpAccessToken()}`;
			}
			return config;
		}
	);
};

const initializeClientSideAccessTokenVerification = (store: Store) => {
	axios.interceptors.request.use(
		async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
			if (config.url && isAccessTokenRequired(config.url)) {
				const userSession = store.getState().userSession as UserSessionState;

				if (!userSession.isActive) {
					handleUnathorized(store);
					throw Error("User session is not active.");
				}

				// if access token is expired check refresh token
				if (
					userSession.session &&
					userSession.session.accessExpirationTimestamp < moment().unix()
				) {
					// if refresh token expired raise error
					if (
						userSession.session &&
						userSession.session.refreshExpirationTimestamp < moment().unix()
					) {
						handleUnathorized(store);
						throw Error("User session expired.");
					} else {
						// if refresh token not expired, refresh token
						if (!tokenRefreshInProgress) {
							tokenRefreshPromise = refreshToken(store);
						}

						// if token refresh is in progress, pause the original requests until the refresh is done
						if (tokenRefreshInProgress) {
							await tokenRefreshPromise;
						}
					}
				}
			}

			return config;
		}
	);
};

const initialize401ResponseHandler = (store: Store) => {
	axios.interceptors.response.use(
		(response: AxiosResponse): AxiosResponse => response,
		async (error: AxiosError): Promise<any> => {
			if (
				error.response &&
				error.response.status === 401 &&
				error.config.url &&
				isAccessTokenRequired(error.config.url)
			) {
				if (isAccessTokenRequired) {
					const userSession = store.getState()
						.userSessionState as UserSessionState;

					if (
						userSession.session &&
						userSession.session.refreshExpirationTimestamp < moment().unix()
					) {
						handleUnathorized(store);
						throw Error("User session expired.");
					} else {
						// if refresh token is not expired, refresh access token
						if (!tokenRefreshInProgress) {
							tokenRefreshPromise = refreshToken(store);
						}

						// if token refresh is in progress, retry failed request after the refresh is done
						if (tokenRefreshInProgress) {
							await tokenRefreshPromise;
							// retry failed request
							return axios(error.config);
						}
					}
				} else {
					throw error;
				}
			} else {
				throw error;
			}
		}
	);
};

const isAccessTokenRequired = (url: string): boolean => {
	return !ACCESS_TOKEN_EXCLUDED_URLS.some(
		(excludedUrl: string): boolean =>
			(url && url.includes(excludedUrl)) || false
	);
};

const handleUnathorized = (store: Store) => {
	store.dispatch(endSession());
	Auth.clearTokens();
	history.push("/login");
	store.dispatch(
		showSnackbar({ msg: "Session expired or not valid. Please login." })
	);
};

const refreshToken = async (store: Store): Promise<any> => {
	console.log("Refreshing token...");
	tokenRefreshInProgress = true;
	let newAccessTokenData;
	try {
		newAccessTokenData = await Auth.refreshHttpAccessToken();
	} catch (e) {
		handleUnathorized(store);
		throw e;
	}

	store.dispatch(extendSessionDuration(newAccessTokenData.exp));
	console.log("Token refreshed");
	tokenRefreshInProgress = false;
};
