import { Theme, createStyles } from "@material-ui/core";

export const styles = (theme: Theme) =>
	createStyles({
		modal: {
			alignItems: "center",
			justifyContent: "center",
			display: "flex"
		},
		card: {
			width: "100%",
			[theme.breakpoints.up("sm")]: {
				width: 700
			}
		},
		loaderBox: {
			position: "absolute",
			top: 0,
			right: 0,
			bottom: 0,
			left: 0,
			backgroundColor: theme.palette.background.paper
		}
	});
