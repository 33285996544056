import { combineReducers } from "redux";
import snackbarReducer from "components/sharedComponents/snackbar/reducer";
import { userSessionReducer } from "modules/userSession/reducer";
import { userListReducer } from "components/users/reducer";
import { clusterListReducer } from "components/management/cluster/reducer";
import { nodeListReducer } from "components/management/node/reducer";
import { hostListReducer } from "components/management/host/reducer";
import { jobListReducer } from "modules/jobs/reducer";
import { clusterCreateWizardReducer } from "components/management/cluster/clusterCreateWizard/reducer";
import { nodeCreateWizardReducer } from "components/management/node/nodeCreateWizard/reducer";
import { webSocketHandlerReducer } from "modules/webSocketHandler/reducer";
import { preloaderReducer } from "modules/preloader/reducer";

export const rootReducer = combineReducers({
	preloader: preloaderReducer,
	userSession: userSessionReducer,
	snackbar: snackbarReducer,
	userList: userListReducer,
	clusterList: clusterListReducer,
	nodeList: nodeListReducer,
	hostList: hostListReducer,
	jobList: jobListReducer,
	clusterCreateWizard: clusterCreateWizardReducer,
	nodeCreateWizard: nodeCreateWizardReducer,
	webSocketHandler: webSocketHandlerReducer
});

export type AppState = ReturnType<typeof rootReducer>;
