import {
	NODE_CREATE_WIZARD_ACTION,
	NodeCreateWizardAction,
	NodeCreateWizardState
} from "components/management/node/nodeCreateWizard/types";
import { Node, NODE_DB_ENGINE } from "components/management/node/types";
import { Host, HOST_SYSTEM, HOST_TYPE } from "components/management/host/types";
import { JOB_STATUS } from "modules/jobs/types";
import { Cluster } from "components/management/cluster/types";

const getInitialState = (): NodeCreateWizardState => {
	const nodeDefaults: Node = {
		name: "nn",
		dbEngine: NODE_DB_ENGINE.MARIADB_10_3,
		segment: 0,
		cluster: "",
		host: "hh"
	};

	const hostDefaults: Host = {
		name: "hh",
		cluster: "",
		system: HOST_SYSTEM.UBUNTU_18,
		type: HOST_TYPE.LOCAL_LXD,
		privateKey: "",
		authorizedKeys: []
	};

	const cluster: Cluster = {
		name: ",",
		owner: "",
		nodeDefaults,
		hostDefaults: {
			type: HOST_TYPE.LOCAL_LXD,
			system: HOST_SYSTEM.UBUNTU_18,
			privateKey: "",
			privateKeyPassword: "",
			authorizedKeys: []
		},
		nodes: [],
		hosts: []
	};

	// const logLine = JSON.parse(
	// 	'{"value":{"channel-type":"stdout","entity-name":"hhsdfasrwqasdf44443","entity-type":"host","level":"info","msg":"Test msg...Test msg...Test msg...Test msg...Test msg...Test msg...Test msg...Test msg...Test msg...Test msg...Test msg...Test msg...Test msg...Test msg...Test msg...Test msg...Test msg...Test msg...","time":"2019-06-03T12:30:59Z"}}'
	// );

	// console.log("logLine", logLine);

	return {
		isOpen: false,
		startNodeAfterDeployment: true,
		activeStep: 0,
		progressMessage: "",
		hasErrorHappened: false,
		errorMessage: "",
		log: [],
		isCreated: false,
		progressPercent: 0,
		cluster: cluster,
		node: nodeDefaults,
		host: hostDefaults,
		hostDeleteErrorMessage: "",
		isHostDeployed: false,
		isHostDeletionInProgress: false,
		hasErrorHappenedDuringHostDeletion: false,
		hostDeployJob: {
			id: -1,
			status: JOB_STATUS.NONE
		},
		hostDeleteJob: {
			id: -1,
			status: JOB_STATUS.NONE
		},
		nodeInstallJob: {
			id: -1,
			status: JOB_STATUS.NONE
		}
	};
};

const initialState: NodeCreateWizardState = getInitialState();

export function nodeCreateWizardReducer(
	state: NodeCreateWizardState = initialState,
	action: NodeCreateWizardAction
): NodeCreateWizardState {
	switch (action.type) {
		case NODE_CREATE_WIZARD_ACTION.SHOW:
			return {
				...state,
				isOpen: true,
				cluster: action.payload.cluster,
				node: {
					...state.node,
					...action.payload.cluster.nodeDefaults,
					cluster: action.payload.cluster.name
				},
				host: {
					...state.host,
					...action.payload.cluster.hostDefaults,
					privateKey: "",
					privateKeyPassword: "",
					authorizedKeys: [],
					cluster: action.payload.cluster.name
				}
			};

		case NODE_CREATE_WIZARD_ACTION.HIDE:
			return initialState;

		case NODE_CREATE_WIZARD_ACTION.GO_TO_DATA_ENTRY:
			return {
				...state,
				activeStep: 0
			};

		case NODE_CREATE_WIZARD_ACTION.GO_TO_DEPLOY_VIEW:
			return {
				...state,
				activeStep: 1
			};

		case NODE_CREATE_WIZARD_ACTION.GO_TO_FINISH:
			return {
				...state,
				activeStep: 2
			};

		case NODE_CREATE_WIZARD_ACTION.SET_NODE:
			return {
				...state,
				node: action.payload.node
			};

		case NODE_CREATE_WIZARD_ACTION.SET_HOST:
			return {
				...state,
				host: action.payload.host
			};

		case NODE_CREATE_WIZARD_ACTION.APPEND_LOG_DATA:
			return {
				...state,
				log: [...state.log, action.payload.logData]
			};

		case NODE_CREATE_WIZARD_ACTION.TOGGLE_AUTO_START_NODE:
			return {
				...state,
				startNodeAfterDeployment: !state.startNodeAfterDeployment
			};

		case NODE_CREATE_WIZARD_ACTION.DEPLOY_HOST_REQUESTED:
			return {
				...state,
				hasErrorHappened: false,
				errorMessage: "",
				activeStep: 1,
				progressMessage: "Deploying host..."
			};

		case NODE_CREATE_WIZARD_ACTION.DEPLOY_HOST_JOB_STARTED:
			return {
				...state,
				hostDeployJob: action.payload.job
			};

		case NODE_CREATE_WIZARD_ACTION.DEPLOY_HOST_SUCCEDED:
			return {
				...state,
				hostDeployJob: {
					...state.hostDeployJob,
					status: JOB_STATUS.SUCCESS
				},
				isHostDeployed: true,
				progressPercent: 50,
				progressMessage: "Installing node..."
			};

		case NODE_CREATE_WIZARD_ACTION.DEPLOY_HOST_FAILED:
			return {
				...state,
				hasErrorHappened: true,
				errorMessage: action.payload.reason
			};

		case NODE_CREATE_WIZARD_ACTION.DELETE_HOST_JOB_STARTED:
			return {
				...state,
				isHostDeletionInProgress: true,
				hostDeleteJob: action.payload.job
			};

		case NODE_CREATE_WIZARD_ACTION.DELETE_HOST_SUCCEDDED:
			return {
				...state,
				progressMessage: "",
				progressPercent: 0,
				isHostDeployed: false,
				isHostDeletionInProgress: false,
				hostDeleteJob: {
					...state.hostDeleteJob,
					status: JOB_STATUS.SUCCESS
				}
			};

		case NODE_CREATE_WIZARD_ACTION.DELETE_HOST_FAILED:
			return {
				...state,
				isHostDeletionInProgress: false,
				hasErrorHappenedDuringHostDeletion: true,
				hostDeleteErrorMessage: action.payload.reason,
				hostDeleteJob: {
					...state.hostDeleteJob,
					status: JOB_STATUS.FAILURE
				}
			};

		case NODE_CREATE_WIZARD_ACTION.INSTALL_NODE_REQUESTED:
			return {
				...state,
				hasErrorHappened: false,
				errorMessage: ""
			};

		case NODE_CREATE_WIZARD_ACTION.INSTALL_NODE_JOB_STARTED:
			return {
				...state,
				nodeInstallJob: action.payload.job
			};

		case NODE_CREATE_WIZARD_ACTION.INSTALL_NODE_SUCCEDED:
			return {
				...state,
				activeStep: 2,
				isCreated: true,
				progressPercent: 100,
				progressMessage: "Done!"
			};

		case NODE_CREATE_WIZARD_ACTION.INSTALL_NODE_FAILED:
			return {
				...state,
				hasErrorHappened: true,
				errorMessage: action.payload.reason
			};

		case NODE_CREATE_WIZARD_ACTION.CREATE_ANOTHER_NODE:
			return {
				...initialState,
				isOpen: true,
				cluster: state.cluster,
				node: {
					...initialState.node,
					...state.cluster.nodeDefaults,
					cluster: state.cluster.name
				},
				host: {
					...initialState.host,
					...state.cluster.hostDefaults,
					cluster: state.cluster.name
				}
			};

		default:
			return state;
	}
}
