import React from "react";
import { withStyles, WithStyles } from "@material-ui/core/styles";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	Step,
	StepLabel,
	Stepper,
	Typography
} from "@material-ui/core";
import { styles } from "./styles";
import { connect } from "react-redux";
import { Cluster } from "components/management/cluster/types";
import { SnackbarActionPayload } from "components/sharedComponents//snackbar/types";
import { AppState } from "AppState";
import { ClusterCreateWizardState } from "components/management/cluster/clusterCreateWizard/types";
import { RouteComponentProps, StaticContext, withRouter } from "react-router";
import {
	clusterCreateWizardCreateRequested,
	clusterCreateWizardHide,
	clusterCreateWizardSetCluster
} from "components/management/cluster/clusterCreateWizard/actions";
import { nodeCreateWizardShow } from "components/management/node/nodeCreateWizard/actions";
import HiddenJs from "@material-ui/core/Hidden/HiddenJs";
import ClusterFormComponent from "components/management/cluster/ClusterFormComponent";

// component local state interface
interface LocalState {}

// PROPS
interface ReduxStateProps {
	clusterCreateWizard: ClusterCreateWizardState;
}

interface ReduxDispatchProps {
	showSnackbar: (snackbar: SnackbarActionPayload) => void;
	clusterCreateWizardHide: () => void;
	clusterCreateWizardSetCluster: (cluster: Cluster) => void;
	clusterCreateWizardCreateRequested: (cluster: Cluster) => void;
	nodeCreateWizardShow: (cluster: Cluster) => void;
}

type Props = ReduxStateProps &
	ReduxDispatchProps &
	WithStyles<typeof styles> &
	RouteComponentProps<any, StaticContext, any>;

class ClusterCreateDialogComponent extends React.Component<Props, LocalState> {
	render() {
		const {
			isOpen,
			activeStep,
			cluster,
			hasErrorHappened,
			errorMessage
		} = this.props.clusterCreateWizard;

		const stepper = (
			<>
				<Stepper activeStep={activeStep}>
					<Step key={1}>
						<StepLabel>{"Enter cluster data"}</StepLabel>
					</Step>

					<Step key={2}>
						<StepLabel>{"Finish"}</StepLabel>
					</Step>
				</Stepper>
			</>
		);

		const wizardButtons = (
			<>
				{(activeStep === 0 && (
					<>
						<div style={{ flexGrow: 1 }} />
						<Button
							size="small"
							onClick={(): void => {
								this.props.clusterCreateWizardHide();
							}}
						>
							Cancel
						</Button>
						<Button
							color="primary"
							type="submit"
							form="clusterForm"
							size="small"
						>
							Create
						</Button>
					</>
				)) ||
					(activeStep === 1 && (
						<>
							<div style={{ flexGrow: 1 }} />
							<Button
								size="small"
								onClick={() => {
									this.props.clusterCreateWizardHide();
								}}
							>
								Close
							</Button>
							<Button
								size="small"
								color="primary"
								onClick={() => {
									console.log("showCluster", cluster.name);
									this.props.clusterCreateWizardHide();
									this.props.history.push(
										`/management/clusters/${cluster.name}`
									);
								}}
							>
								Show cluster
							</Button>
							<Button
								size="small"
								color="primary"
								onClick={() => {
									this.props.clusterCreateWizardHide();
									this.props.nodeCreateWizardShow(cluster);
								}}
							>
								Add node
							</Button>
						</>
					))}
			</>
		);

		const clusterForm = (
			<>
				<ClusterFormComponent
					cluster={cluster}
					privateKeySubtitle={
						"Enter private key which will be applied to new hosts in this cluster, unless overridden in host configuration."
					}
					authorizedKeysSubtitle={
						"Add authorized keys which will be applied to all hosts in this cluster."
					}
					onSubmit={(cluster: Cluster) => {
						console.log("clusterSubmitted", cluster);
						this.props.clusterCreateWizardSetCluster(cluster);
						this.props.clusterCreateWizardCreateRequested(cluster);
					}}
				/>
			</>
		);

		const clusterFinishMsg = (
			<>
				<Typography>Cluster successfully created.</Typography>
			</>
		);

		const dialogContent = (
			<>
				<DialogTitle>{stepper}</DialogTitle>
				<DialogContent>
					{hasErrorHappened && (
						<Grid item>
							<Typography variant="subtitle2" color="error">
								{errorMessage}
							</Typography>
						</Grid>
					)}
					{(activeStep === 0 && clusterForm) ||
						(activeStep === 1 && clusterFinishMsg)}
				</DialogContent>
				<DialogActions>{wizardButtons}</DialogActions>
			</>
		);

		return (
			<>
				{/*Mobile*/}
				<HiddenJs mdUp>
					<Dialog
						fullScreen={true}
						disableBackdropClick={true}
						disableEscapeKeyDown={true}
						open={isOpen}
					>
						{dialogContent}
					</Dialog>
				</HiddenJs>

				{/*Desktop*/}
				<HiddenJs smDown>
					<Dialog
						fullScreen={false}
						maxWidth="md"
						fullWidth={true}
						disableBackdropClick={true}
						disableEscapeKeyDown={true}
						open={isOpen}
					>
						{dialogContent}
					</Dialog>
				</HiddenJs>
			</>
		);
	}
}

// REDUX MAPPINGS
const mapGlobalStateToProps = (state: AppState) => ({
	clusterCreateWizard: state.clusterCreateWizard
});

const mapGlobalDispatchToProps = (dispatch: any) => ({
	clusterCreateWizardHide: (): void => {
		dispatch(clusterCreateWizardHide());
	},
	clusterCreateWizardCreateRequested: (cluster: Cluster) => {
		dispatch(clusterCreateWizardCreateRequested(cluster));
	},
	clusterCreateWizardSetCluster: (cluster: Cluster) => {
		dispatch(clusterCreateWizardSetCluster(cluster));
	},
	nodeCreateWizardShow: (cluster: Cluster) => {
		dispatch(nodeCreateWizardShow(cluster));
	}
});

export default withStyles(styles, { withTheme: true })(
	withRouter(
		connect(
			mapGlobalStateToProps,
			mapGlobalDispatchToProps
		)(ClusterCreateDialogComponent)
	)
);
