import axios from "axios";
import jwt from "jsonwebtoken";
import { Credentials } from "modules/userSession/types";
import AppEnvironment from "modules/appEnvironment";

const JWT_PUBLIC_KEY =
	"-----BEGIN PUBLIC KEY-----\n" +
	"MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA4f5wg5l2hKsTeNem/V41\n" +
	"fGnJm6gOdrj8ym3rFkEU/wT8RDtnSgFEZOQpHEgQ7JL38xUfU0Y3g6aYw9QT0hJ7\n" +
	"mCpz9Er5qLaMXJwZxzHzAahlfA0icqabvJOMvQtzD6uQv6wPEyZtDTWiQi9AXwBp\n" +
	"HssPnpYGIn20ZZuNlX2BrClciHhCPUIIZOQn/MmqTD31jSyjoQoV7MhhMTATKJx2\n" +
	"XrHhR+1DcKJzQBSTAGnpYVaqpsARap+nwRipr3nUTuxyGohBTSmjJ2usSeQXHI3b\n" +
	"ODIRe1AuTyHceAbewn8b462yEWKARdpd9AjQW5SIVPfdsz5B6GlYQ5LdYKtznTuy\n" +
	"7wIDAQAB\n" +
	"-----END PUBLIC KEY-----";

class Auth {
	static verifyJwtToken(token: string): JwtTokenData {
		return jwt.verify(token, JWT_PUBLIC_KEY) as JwtTokenData;
	}

	// todo: rename this to 'fetchHTTPToken' or something similar
	// make it low level and don't parse error msg, leave that to higher-level components
	static async login(credentials: Credentials) {
		try {
			const loginResponse = await axios.post(
				`${AppEnvironment.getApiHostAddress()}/${AppEnvironment.getApiVersion()}/jwt/signin`,
				{
					username: credentials.username,
					password: credentials.password
				}
			);

			const accessToken = loginResponse.data.access.value;
			const refreshToken = loginResponse.data.refresh.value;

			const { exp: accessExpirationTimestamp, userRole } = this.verifyJwtToken(
				accessToken
			);
			const { exp: refreshExpirationTimestamp } = this.verifyJwtToken(
				refreshToken
			);
			this.setHttpAccessToken(accessToken);
			this.setRefreshToken(refreshToken);

			return {
				user: {
					username: "admin",
					name: "admin",
					role: userRole
				},
				accessExpirationTimestamp: accessExpirationTimestamp,
				refreshExpirationTimestamp
			};
		} catch (e) {
			console.log("error response", e);

			let errorMessage = "";
			if (e.response) {
				switch (e.response.status) {
					case 401:
						errorMessage = "Wrong username or password.";
						break;
					default:
						errorMessage = `Error ${e.response.status}: ${
							e.response.statusText
						}`;
				}
			} else {
				errorMessage = e.message;
			}

			throw Error(errorMessage);
		}
	}

	// todo: rename this to 'clearHTTPToken'
	static async clearTokens(): Promise<void> {
		setTimeout(() => {
			localStorage.removeItem("httpAccessToken");
			localStorage.removeItem("refreshToken");
			return;
		});
	}

	static async refreshHttpAccessToken(): Promise<JwtTokenData> {
		try {
			const response = await axios.post(
				`${AppEnvironment.getApiHostAddress()}/${AppEnvironment.getApiVersion()}/jwt/refresh`,
				{},
				{ headers: { Authorization: `Bearer ${this.getRefreshToken()}` } }
			);

			const httpAccessToken = response.data.value;
			const httpAccessTokenData = this.verifyJwtToken(httpAccessToken);
			this.setHttpAccessToken(httpAccessToken);
			return httpAccessTokenData;
		} catch (errorResponse) {
			let errorMessage = "";
			if (errorResponse.response) {
				errorMessage = `Error ${errorResponse.response.status}: ${
					errorResponse.response.statusText
				}`;
			} else {
				errorMessage = `${errorResponse.name}: ${errorResponse.message}`;
			}

			throw Error(errorMessage);
		}
	}

	static async refreshWsAccessToken(): Promise<JwtTokenData> {
		try {
			const response = await axios.post(
				`${AppEnvironment.getApiHostAddress()}/${AppEnvironment.getApiVersion()}/jwt/websocket`,
				{},
				{ headers: { Authorization: `Bearer ${this.getRefreshToken()}` } }
			);

			const wsAccessToken = response.data.value;
			const wsAccessTokenData = this.verifyJwtToken(wsAccessToken);
			this.setWsAccessToken(wsAccessToken);
			return wsAccessTokenData;
		} catch (errorResponse) {
			let errorMessage = "";
			if (errorResponse.response) {
				errorMessage = `Error ${errorResponse.response.status}: ${
					errorResponse.response.statusText
				}`;
			} else {
				errorMessage = `${errorResponse.name}: ${errorResponse.message}`;
			}

			throw Error(errorMessage);
		}
	}

	private static setHttpAccessToken(token: string) {
		localStorage.setItem("httpAccessToken", token);
	}

	static getHttpAccessToken(): string {
		return localStorage.getItem("httpAccessToken") || "";
	}

	private static setWsAccessToken(token: string) {
		localStorage.setItem("wsAccessToken", token);
	}

	static getWsAccessToken(): string {
		return localStorage.getItem("wsAccessToken") || "";
	}

	private static setRefreshToken(token: string) {
		localStorage.setItem("refreshToken", token);
	}

	static getRefreshToken(): string {
		return localStorage.getItem("refreshToken") || "";
	}
}

export default Auth;
