import {
	NODE_ACTION,
	NodeListFetchSuccededAction
} from "components/management/node/types";
import { Node } from "./types";

export const nodeListFetchRequested = () => ({
	type: NODE_ACTION.LIST_FETCH_REQUESTED
});

export const nodeListFetchSucceded = (
	nodeList: Node[]
): NodeListFetchSuccededAction => ({
	type: NODE_ACTION.LIST_FETCH_SUCCEDED,
	payload: {
		nodeList
	}
});

export const nodeCreateRequested = (node: Node) => ({
	type: NODE_ACTION.CREATE_REQUESTED,
	payload: {
		node
	}
});

export const nodeCreateSucceded = (node: Node) => ({
	type: NODE_ACTION.CREATE_SUCCEDED,
	payload: {
		node
	}
});
