import { eventChannel } from "redux-saga";
import { all, call, put, race, take, takeEvery } from "redux-saga/effects";
import AppEnvironment from "modules/appEnvironment";
import { WEB_SOCKET_HANDLER_ACTION } from "modules/webSocketHandler/types";
import {
	webSocketHandlerConnectionAuthenticationRequested,
	webSocketHandlerConnectionCloseRequestedAction,
	webSocketHandlerConnectionCloseSuccededAction,
	webSocketHandlerConnectionFailed,
	webSocketHandlerConnectionMessageSendRequested,
	webSocketHandlerConnectionOpenSucceded
} from "modules/webSocketHandler/actions";
import { USER_SESSION_ACTION } from "modules/userSession/types";
import Auth from "modules/auth/Auth";
import { nodeCreateWizardAppendLogData } from "components/management/node/nodeCreateWizard/actions";

function wsBinder(socket: WebSocket) {
	return eventChannel(emitter => {
		socket.onopen = (e: Event) => emitter(e);
		socket.onclose = (e: Event) => emitter(e);
		socket.onmessage = (e: Event) => emitter(e);
		socket.onerror = (e: Event) => emitter(e);
		return () => {
			socket.close();
		};
	});
}

// this function handles all events received from WebSocket
function* wsEventHandler(socketChannel: any) {
	// console.log("wsEventHandler");
	while (true) {
		const wsEvent = yield take(socketChannel);
		// console.log("WS event received", wsEvent);

		switch (wsEvent.type) {
			case "open":
				yield put(webSocketHandlerConnectionOpenSucceded());
				yield put(webSocketHandlerConnectionAuthenticationRequested());
				break;

			case "message":
				yield wsMessageEventHandler(JSON.parse(wsEvent.data));
				break;

			case "close":
				yield put(webSocketHandlerConnectionCloseSuccededAction());
				break;

			case "error":
				console.warn("WebSocket connection error:", wsEvent);
				yield put(webSocketHandlerConnectionFailed("Failed!"));
				break;

			default:
				console.warn(`Unhandled WebSocket event type: ${wsEvent.type}`);
		}
	}
}

// this function handler all message events received from WebSocket
function* wsMessageEventHandler(msg: any) {
	// console.log("message received", msg);
	switch (msg.type.toLowerCase()) {
		case "auth":
			yield put(webSocketHandlerConnectionAuthenticationRequested());
			break;

		case "log":
			if (msg.value.msg.trim())
				yield put(nodeCreateWizardAppendLogData(msg.value));
			// yield put(webSocketHandlerLogMessageReceived(msg.value));
			break;

		default:
			console.warn(`Unknown WebSocket message type received: ${msg.type}`);
	}
}

// this function handles all requests for sending messages via WebSocket
// todo: queue send if socket is not yet ready
function* wsMessageSendHandler(socket: WebSocket) {
	// console.log("wsMessageSendHandler", socket.readyState);
	while (true) {
		const action = yield take(WEB_SOCKET_HANDLER_ACTION.MESSAGE_SEND_REQUESTED);
		// console.log("send ws message", action.payload.message);
		socket.send(JSON.stringify(action.payload.message));
	}
}

function* initializeWebSocketHandler() {
	// console.log("initialize web sockets channel");
	const socket = new WebSocket(`${AppEnvironment.getWsHostAddress()}/v1/ws`);

	while (
		socket.readyState === WebSocket.OPEN ||
		socket.readyState === WebSocket.CONNECTING
	) {
		const socketChannel = yield call(wsBinder, socket);
		const { cancel } = yield race({
			task: all([
				call(wsEventHandler, socketChannel),
				call(wsMessageSendHandler, socket)
			]),
			cancel: take(WEB_SOCKET_HANDLER_ACTION.CONNECTION_CLOSE_REQUESTED)
		});
		if (cancel) {
			console.log("cancel");
			socketChannel.close();
			yield put(webSocketHandlerConnectionCloseSuccededAction());
		}
	}
}

function* closeConnectionHandler() {
	yield webSocketHandlerConnectionCloseRequestedAction();
}

function* authenticationRequested() {
	yield Auth.refreshWsAccessToken();
	yield put(
		webSocketHandlerConnectionMessageSendRequested({
			type: "auth",
			value: Auth.getWsAccessToken()
		})
	);
}

export function* WebSocketHandlerSideEffects() {
	yield all([
		takeEvery(USER_SESSION_ACTION.START_SESSION, initializeWebSocketHandler),
		takeEvery(USER_SESSION_ACTION.END_SESSION, closeConnectionHandler),
		takeEvery(
			WEB_SOCKET_HANDLER_ACTION.CONNECTION_AUTHENTICATION_REQUESTED,
			authenticationRequested
		)
	]);
}
