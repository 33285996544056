import {
	Avatar,
	Box,
	CardHeader,
	Divider,
	Grid,
	Tab,
	Tabs,
	TextField,
	Typography,
	WithTheme
} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import { withStyles, WithStyles } from "@material-ui/core/styles";
import { Storage } from "@material-ui/icons";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { AppState } from "AppState";
import { Host } from "components/management/host/types";
import { Node } from "components/management/node/types";
import HostsApi from "modules/api/HostsApi";
import NodesApi from "modules/api/NodesApi";
import { showSnackbar } from "components/sharedComponents/snackbar/actionCreators";
import { SnackbarActionPayload } from "components/sharedComponents/snackbar/types";
import React, { ChangeEvent } from "react";
import { connect } from "react-redux";
import { StaticContext } from "react-router";
import { RouteComponentProps } from "react-router-dom";
import { styles } from "./styles";
import LogsApi from "modules/api/LogsApi";
import { AxiosResponse } from "axios";
import LogTable from "components/sharedComponents/logTable/LogTableComponent";
import PrivateKeyEditor from "components/sharedComponents/privateKeyEditor/PrivateKeyEditorComponent";
import AuthorizedKeysEditor from "components/sharedComponents/authorizedKeysEditor/AuthorizedKeysEditorComponent";
import { Cluster } from "components/management/cluster/types";
import ClustersApi from "modules/api/ClustersApi";

// component local state interface
interface LocalState {
	cluster: Cluster;
	node: Node;
	host: Host;
	activeTab: number;
	logs: any[];
}

// PROPS
interface ReduxStateProps {
	clusterList: Cluster[];
	nodeList: Node[];
	hostList: Host[];
}

interface ReduxDispatchProps {
	showSnackbar: (snackbar: SnackbarActionPayload) => void;
}

type Props = ReduxStateProps &
	ReduxDispatchProps &
	WithStyles<typeof styles> &
	WithTheme &
	RouteComponentProps<any, StaticContext, any>;

// COMPONENT
class NodeComponent extends React.Component<Props, LocalState> {
	constructor(props: Props) {
		super(props);

		const node = this.getNode();

		if (node) {
			const cluster = this.getCluster(node.cluster);
			if (cluster) {
				const host = this.getHost(node);

				if (host) {
					this.state = { cluster, node, host, activeTab: 0, logs: [] };
					this.getLogs();
				}
			}
		}
	}

	getLogs = () => {
		LogsApi.fetchHostLogList(this.state.host.name).then(
			(response: AxiosResponse) => {
				// console.log("hostLogsApi", response);
				LogsApi.fetchHostLog(this.state.host.name, response.data[1]).then(
					(response: AxiosResponse) => {
						// console.log("hostLog", response.data);
						// console.log("hostLog parsed", response.data.split("\n"));

						this.setState((state: LocalState) => ({
							...state,
							logs: response.data
								.split("\n")
								.map((row: string) => ({ msg: row }))
						}));
					}
				);
			}
		);
	};

	getCluster = (clusterName: string): Cluster | undefined => {
		const cluster = this.props.clusterList.find((cluster: Cluster) => {
			return cluster.name === clusterName;
		});

		if (!cluster) {
			this.props.showSnackbar({
				msg: `Unable to find cluster "${clusterName}"`
			});
			this.props.history.push("/management");
			return;
		}

		return cluster;
	};

	getNode = (): Node | undefined => {
		const node = this.props.nodeList.find((node: Node) => {
			return node.name === this.props.match.params.name;
		});

		if (!node) {
			this.props.showSnackbar({
				msg: `Unable to find node "${this.props.match.params.name}"`
			});
			this.props.history.push("/management");
			return;
		}

		return node;
	};

	getHost = (node: Node): Host | undefined => {
		const host = this.props.hostList.find((host: Host) => {
			return host.name === node.host;
		});

		if (!host) {
			this.props.showSnackbar({
				msg: `Unable to find host "${node.host}"`
			});
			this.props.history.push("/management");
			return;
		}

		return host;
	};

	componentWillReceiveProps = (newProps: Props) => {
		console.log("componentWillRecieveProps", newProps);

		if (newProps.match.params.name !== this.state.node.name) {
			const node = NodesApi.fetch(newProps.match.params.name);
			const host = HostsApi.fetch(node.host);
			const cluster = ClustersApi.fetch(node.cluster);
			console.log("node data", node);
			console.log("host data", host);

			this.setState((state: LocalState) => ({
				...state,
				node,
				host,
				cluster,
				logs: []
			}));
			this.getLogs();
		}
	};

	onDeleteClick = (): void => {
		HostsApi.delete(this.state.host);

		this.props.showSnackbar({
			msg: `Node '${this.state.node.name}' deleted.`
		});
		this.props.history.push("/management");
	};

	render(): React.ReactNode {
		const { classes, theme } = this.props;

		if (!this.state) return false;

		return (
			<Card className={classes.card}>
				<CardHeader
					avatar={
						<Avatar
							style={{ backgroundColor: theme.palette.primary.main }}
							aria-label="Node"
						>
							<Storage />
						</Avatar>
					}
					action={
						<>
							<IconButton>
								<MoreVertIcon />
							</IconButton>
						</>
					}
					title={this.state.node.name}
					subheader={this.state.node.dbEngine}
				/>
				<Divider />
				<Tabs
					value={this.state.activeTab}
					onChange={(event: ChangeEvent<{}>, newValue: any) => {
						// console.log("onChange", event, newValue);
						this.setState((state: LocalState) => ({
							...state,
							activeTab: newValue
						}));
					}}
					// indicatorColor="primary"
					// textColor="primary"
					variant="fullWidth"
				>
					<Tab label="Settings" />
					<Tab label="Logs" />
				</Tabs>
				<CardContent>
					{(this.state.activeTab === 0 && (
						<Grid container direction="column" spacing={2}>
							<Grid container item direction="column">
								<Grid item>
									<Typography variant="subtitle2">
										Node configuration
									</Typography>
								</Grid>
								<Grid container item direction="row" spacing={2}>
									<Grid item sm={6} xs={12}>
										<TextField
											fullWidth={true}
											margin="dense"
											label="Node name"
											value={this.state.node.name}
											inputProps={{ readOnly: true }}
										/>
									</Grid>
									<Grid item sm={6} xs={12}>
										<TextField
											fullWidth={true}
											margin="dense"
											label="DB Engine"
											value={this.state.node.dbEngine}
											inputProps={{ readOnly: true }}
										/>
									</Grid>
								</Grid>
								<Grid container item direction="row" spacing={2}>
									<Grid item sm={6} xs={12}>
										<TextField
											fullWidth={true}
											margin="dense"
											label="Segment"
											value={this.state.node.segment}
											inputProps={{ readOnly: true }}
										/>
									</Grid>
								</Grid>
							</Grid>
							<Grid container item direction="column">
								<Grid item>
									<Typography variant="subtitle2">
										Host configuration
									</Typography>
									{/*<Divider />*/}
								</Grid>
								<Grid container direction="row" spacing={2}>
									<Grid item sm={6} xs={12}>
										<TextField
											fullWidth={true}
											margin="dense"
											label="Host name"
											value={this.state.host.name}
											inputProps={{ readOnly: true }}
										/>
									</Grid>
									<Grid item sm={6} xs={12}>
										<TextField
											fullWidth={true}
											margin="dense"
											label="System"
											value={this.state.host.system}
											inputProps={{ readOnly: true }}
										/>
									</Grid>
								</Grid>
								<Grid container direction="row" spacing={2}>
									<Grid item sm={6} xs={12}>
										<TextField
											fullWidth={true}
											margin="dense"
											label="Type"
											value={this.state.host.type}
											inputProps={{ readOnly: true }}
										/>
									</Grid>
								</Grid>
								<Grid container direction="row" spacing={2}>
									<Grid item sm={6} xs={12}>
										<PrivateKeyEditor
											readOnly={true}
											inheritedPrivateKey={
												this.state.cluster.hostDefaults.privateKey
											}
											privateKey={this.state.host.privateKey}
											onSubmit={() => {}}
										/>
									</Grid>
									<Grid item sm={6} xs={12}>
										<AuthorizedKeysEditor
											subtitleText={
												"Below is the list of all currently active authorized keys (including the ones inherited from cluster)."
											}
											authorizedKeys={this.state.host.authorizedKeys}
											inheritedAuthorizedKeys={
												this.state.cluster.hostDefaults.authorizedKeys
											}
											readOnly={true}
											onAdd={(): void => {}}
											onRemove={(): void => {}}
										/>
									</Grid>
								</Grid>
								<Grid container direction="row" spacing={2}>
									<Grid item sm={6} xs={12}>
										<TextField
											fullWidth={true}
											margin="dense"
											label="SSH Address"
											value={this.state.host.ssh && this.state.host.ssh.address}
											inputProps={{ readOnly: true }}
										/>
									</Grid>
									<Grid item sm={6} xs={12}>
										<TextField
											fullWidth={true}
											margin="dense"
											label="SSH Port"
											value={this.state.host.ssh && this.state.host.ssh.port}
											inputProps={{ readOnly: true }}
										/>
									</Grid>
								</Grid>
								<Grid container direction="row" spacing={2}>
									<Grid item sm={6} xs={12}>
										<TextField
											fullWidth={true}
											margin="dense"
											label="DB Address"
											value={this.state.host.db && this.state.host.db.address}
											inputProps={{ readOnly: true }}
										/>
									</Grid>
									<Grid item sm={6} xs={12}>
										<TextField
											fullWidth={true}
											margin="dense"
											label="DB Port"
											value={this.state.host.db && this.state.host.db.port}
											inputProps={{ readOnly: true }}
										/>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					)) ||
						(this.state.activeTab === 1 && (
							<Box style={{ height: 500 }}>
								<LogTable
									rowCount={this.state.logs.length}
									rowGetter={({ index }) => this.state.logs[index]}
									columns={[
										{
											width: 200,
											flexGrow: 1,
											label: "Message",
											dataKey: "msg"
										}
									]}
								/>
							</Box>
						))}
				</CardContent>
				{/*<Divider />*/}
				{/*<CardActions>*/}
				{/*	<Button size="small" color="secondary" onClick={this.onDeleteClick}>Delete</Button>*/}
				{/*</CardActions>*/}
			</Card>
		);
	}
}

// REDUX MAPPINGS
const mapGlobalStateToProps = (state: AppState) => ({
	nodeList: state.nodeList,
	hostList: state.hostList,
	clusterList: state.clusterList
});

const mapGlobalDispatchToProps = (dispatch: any) => {
	return {
		showSnackbar: (snackbar: SnackbarActionPayload) => {
			dispatch(showSnackbar(snackbar));
		}
	};
};

export default withStyles(styles, { withTheme: true })(
	connect(
		mapGlobalStateToProps,
		mapGlobalDispatchToProps
	)(NodeComponent)
);
