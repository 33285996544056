export interface Node {
	id?: number;
	name: string;
	cluster: string;
	dbEngine: NODE_DB_ENGINE;
	host: string;
	deploymentStatus?: string;
	segment: number;
	first?: boolean;
}

export interface NodeDefaults {
	dbEngine: NODE_DB_ENGINE;
}

export enum NODE_DB_ENGINE {
	MARIADB_10_3 = "mariadb:10.3"
}

export enum NODE_ACTION {
	LIST_FETCH_REQUESTED = "NODE/LIST_FETCH_REQUESTED",
	LIST_FETCH_SUCCEDED = "NODE/LIST_FETCH_SUCCEDED",
	CREATE_REQUESTED = "NODE/CREATE_REQUESTED",
	CREATE_SUCCEDED = "NODE/CREATE_SUCCEDED"
}

export interface NodeListFetchRequestedAction {
	type: NODE_ACTION.LIST_FETCH_REQUESTED;
}

export interface NodeListFetchSuccededAction {
	type: NODE_ACTION.LIST_FETCH_SUCCEDED;
	payload: {
		nodeList: Node[];
	};
}

export interface NodeCreateRequestedAction {
	type: NODE_ACTION.CREATE_REQUESTED;
	payload: {
		node: Node;
	};
}

export interface NodeCreateSuccededAction {
	type: NODE_ACTION.CREATE_SUCCEDED;
	payload: {
		node: Node;
	};
}

export type NodeAction =
	| NodeListFetchRequestedAction
	| NodeListFetchSuccededAction
	| NodeCreateRequestedAction
	| NodeCreateSuccededAction;
