import React from "react";
import { Router, Redirect, Route, Switch } from "react-router-dom";
import Login from "components/login/LoginComponent";
import { History } from "history";
import RootComponent from "components/root/RootComponent";

interface RouterProps {
	history: History;
}

class GMRouter extends React.Component<RouterProps> {
	render() {
		return (
			<Router history={this.props.history}>
				<Switch>
					<Route path="/login" component={Login} />
					<Route path="/" component={RootComponent} />
					<Redirect from="*" to="/login" />
				</Switch>
			</Router>
		);
	}
}

export default GMRouter;
