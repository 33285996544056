import React, {FormEvent} from "react";
import {AppState} from "AppState";
import {connect} from "react-redux";
import {Button, Typography} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import logo from "assets/images/gc_logo_orange.png";
import "./LoginComponent.sass";
import CircularProgress from "@material-ui/core/CircularProgress";
import {History} from "history";
import Auth from "modules/auth/Auth";
import {SnackbarActionPayload} from "components/sharedComponents/snackbar/types";
import {showSnackbar} from "components/sharedComponents/snackbar/actionCreators";
import {Credentials, UserSession, UserSessionState} from "modules/userSession/types";
import {startSession} from "modules/userSession/actions";

// component local state interface
interface LoginComponentState {
	credentials: Credentials;
	loading: boolean;
}

// PROPS
interface StateProps {
	userSession: UserSessionState;
	history: History;
}

interface DispatchProps {
	startSession: (session: UserSession) => void;
	showSnackbar: (snackbar: SnackbarActionPayload) => void;
}

type LoginComponentProps = StateProps & DispatchProps;

// COMPONENT
class LoginComponent extends React.Component<
	LoginComponentProps,
	LoginComponentState
> {
	constructor(props: LoginComponentProps) {
		super(props);

		this.state = {
			credentials: {
				username: "",
				password: ""
			},
			loading: false
		};
	}

	readonly handleClick = (event: FormEvent) => {
		event.preventDefault();
		this.setState({ ...this.state, loading: true });

		Auth.login(this.state.credentials).then(
			(userSession: UserSession) => {
				this.setState({ ...this.state, loading: false });
				this.props.startSession(userSession);
				this.props.showSnackbar({
					msg: "Welcome to Galera Manager!"
				});
				this.props.history.push("/management");
			},
			(error: Error) => {
				this.setState({ ...this.state, loading: false });

				this.props.showSnackbar({
					msg: error.message
				});
			}
		);
	};

	render(): React.ReactNode {
		return (
			<>
				<Grid
					container
					alignItems="center"
					alignContent="center"
					direction="column"
					className="login-page-container"
				>
					<Grid item>
						<img className="logo" src={logo} alt="Galera Cluster Logo" />
					</Grid>
					<Grid item className="form-grid-item">
						<Card className="form-container">
							<form onSubmit={this.handleClick}>
								<CardContent>
									<Typography variant="h5" align="center">
										Galera Manager Login
									</Typography>
									<Grid container direction="column" spacing={1}>
										<TextField
											fullWidth={true}
											margin="dense"
											autoComplete="username"
											label="Username"
											value={this.state.credentials.username}
											onChange={e =>
												this.setState({
													...this.state,
													credentials: {
														...this.state.credentials,
														username: e.target.value
													}
												})
											}
										/>
										<TextField
											fullWidth={true}
											margin="dense"
											autoComplete="current-password"
											label="Password"
											type="password"
											value={this.state.credentials.password}
											onChange={e =>
												this.setState({
													...this.state,
													credentials: {
														...this.state.credentials,
														password: e.target.value
													}
												})
											}
										/>
									</Grid>
								</CardContent>
								<CardActions>
									<Button
										type="submit"
										disabled={this.state.loading}
										fullWidth={true}
										color={"primary"}
										variant={"contained"}
									>
										{this.state.loading && (
											<CircularProgress className="login-loader" size={20} />
										)}
										Login
									</Button>
								</CardActions>
							</form>
						</Card>
					</Grid>
				</Grid>
			</>
		);
	}
}

// REDUX MAPPINGS
const mapGlobalStateToProps = (state: AppState) => {
	return {
		userSession: state.userSession
	};
};

const mapGlobalDispatchToProps = (dispatch: any) => {
	return {
		startSession: (session: UserSession) => {
			dispatch(startSession(session));
		},
		showSnackbar: (snackbar: SnackbarActionPayload) => {
			dispatch(showSnackbar(snackbar));
		}
	};
};

export default connect(
	mapGlobalStateToProps,
	mapGlobalDispatchToProps
)(LoginComponent);
