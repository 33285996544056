import { withStyles, WithStyles } from "@material-ui/core/styles";
import { AppState } from "AppState";
import { userListFetchRequested } from "components/users/actions";
import { User } from "components/users/types";
import React from "react";
import { connect } from "react-redux";
import { StaticContext } from "react-router";
import { RouteComponentProps } from "react-router-dom";
import { styles } from "./styles";
import {
	Avatar,
	Button,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	ListSubheader,
	Paper
} from "@material-ui/core";
import { Person } from "@material-ui/icons";
import UserUtils from "components/users/utils";

// global state props
interface LocalProps {
	userList: User[];
}

// global dispatch props
interface DispatchProps {
	userListFetchRequested: () => void;
}

type Props = LocalProps &
	DispatchProps &
	WithStyles<typeof styles> &
	RouteComponentProps<any, StaticContext, any>;

// local state
interface LocalState {}

// COMPONENT
class UserListComponent extends React.Component<Props, LocalState> {
	constructor(props: Props) {
		super(props);
		// todo or not todo: request new data each time component is created (list is displayed)
		// to prevent displaying outdated data

		// this.props.userListFetchRequested();

		this.state = {};
	}

	componentWillReceiveProps = (newProps: Props) => {
		console.log("componentWillRecieveProps", newProps);
	};
	// <Card className={classes.card}>
	// <CardContent>
	// User list:
	// {this.props.userList.map((user: User, index: number) => {
	//   return <p key={index}>{user.name}</p>;
	// })}
	// </CardContent>
	// </Card>

	render(): React.ReactNode {
		const { classes } = this.props;

		return (
			<>
				<Paper className={classes.root}>
					<List subheader={<ListSubheader>Users</ListSubheader>}>
						{this.props.userList.map((user: User, index: number) => {
							return (
								<ListItem
									key={user.id}
									button
									onClick={(): void => {
										console.log("onItemClick");
									}}>
									<ListItemAvatar>
										<Avatar>
											<Person />
										</Avatar>
									</ListItemAvatar>
									<ListItemText
										primary={user.name}
										secondary={UserUtils.getUserRoleName(user.role)}
									/>
								</ListItem>
							);
						})}
					</List>
				</Paper>
				<Button
					fullWidth={true}
					onClick={() => {
						console.log("onItemClick");
						// this.props.clusterCreateDialogShow();
						// this.props.history.push(`/management/clusters/new`);
					}}
				>
					Create new user
				</Button>
			</>
		);
	}
}

// global state and dispatch mappings
const mapGlobalStateToProps = (state: AppState) => {
	return {
		userList: state.userList
	};
};

const mapGlobalDispatchToProps = (dispatch: any) => {
	return {
		userListFetchRequested: () => {
			dispatch(userListFetchRequested());
		}
	};
};

export default withStyles(styles, { withTheme: true })(
	connect(
		mapGlobalStateToProps,
		mapGlobalDispatchToProps
	)(UserListComponent)
);
