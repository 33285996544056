import { Host } from "components/management/host/types";
import {
	NODE_CREATE_WIZARD_ACTION,
	NodeCreateWizardAppendLogDataAction,
	NodeCreateWizardCreateAnotherNodeAction,
	NodeCreateWizardDeleteHostFailedAction,
	NodeCreateWizardDeleteHostJobStartedAction,
	NodeCreateWizardDeleteHostRequestedAction,
	NodeCreateWizardDeleteHostSuccededAction,
	NodeCreateWizardDeployHostFailedAction,
	NodeCreateWizardDeployHostJobStartedAction,
	NodeCreateWizardDeployHostRequestedAction,
	NodeCreateWizardDeployHostSuccededAction,
	NodeCreateWizardGoToDataEntryAction,
	NodeCreateWizardGoToDeployViewAction,
	NodeCreateWizardGoToFinishAction,
	NodeCreateWizardHideAction,
	NodeCreateWizardInstallNodeFailedAction,
	NodeCreateWizardInstallNodeJobStartedAction,
	NodeCreateWizardInstallNodeRequestedAction,
	NodeCreateWizardInstallNodeSuccededAction,
	NodeCreateWizardSetHostAction,
	NodeCreateWizardSetNodeAction,
	NodeCreateWizardShowAction,
	NodeCreateWizardToggleAutoStartNodeAction
} from "components/management/node/nodeCreateWizard/types";
import { Node } from "components/management/node/types";
import { Cluster } from "components/management/cluster/types";
import { Job } from "modules/jobs/types";

export const nodeCreateWizardShow = (
	cluster: Cluster
): NodeCreateWizardShowAction => ({
	type: NODE_CREATE_WIZARD_ACTION.SHOW,
	payload: {
		cluster
	}
});

export const nodeCreateWizardHide = (): NodeCreateWizardHideAction => ({
	type: NODE_CREATE_WIZARD_ACTION.HIDE
});

export const nodeCreateWizardSetNode = (
	node: Node
): NodeCreateWizardSetNodeAction => ({
	type: NODE_CREATE_WIZARD_ACTION.SET_NODE,
	payload: {
		node
	}
});

export const nodeCreateWizardAppendLogData = (
	logData: any
): NodeCreateWizardAppendLogDataAction => ({
	type: NODE_CREATE_WIZARD_ACTION.APPEND_LOG_DATA,
	payload: {
		logData
	}
});

export const nodeCreateWizardGoToDataEntry = (): NodeCreateWizardGoToDataEntryAction => ({
	type: NODE_CREATE_WIZARD_ACTION.GO_TO_DATA_ENTRY
});

export const nodeCreateWizardGoToDeployView = (): NodeCreateWizardGoToDeployViewAction => ({
	type: NODE_CREATE_WIZARD_ACTION.GO_TO_DEPLOY_VIEW
});

export const nodeCreateWizardGoToFinish = (): NodeCreateWizardGoToFinishAction => ({
	type: NODE_CREATE_WIZARD_ACTION.GO_TO_FINISH
});

export const nodeCreateWizardSetHost = (
	host: Host
): NodeCreateWizardSetHostAction => ({
	type: NODE_CREATE_WIZARD_ACTION.SET_HOST,
	payload: {
		host
	}
});

export const nodeCreateWizardToggleAutoStartNode = (): NodeCreateWizardToggleAutoStartNodeAction => ({
	type: NODE_CREATE_WIZARD_ACTION.TOGGLE_AUTO_START_NODE
});

export const nodeCreateWizardDeployHostRequested = (
	host: Host
): NodeCreateWizardDeployHostRequestedAction => ({
	type: NODE_CREATE_WIZARD_ACTION.DEPLOY_HOST_REQUESTED,
	payload: {
		host
	}
});

export const nodeCreateWizardDeployHostJobStarted = (
	job: Job
): NodeCreateWizardDeployHostJobStartedAction => ({
	type: NODE_CREATE_WIZARD_ACTION.DEPLOY_HOST_JOB_STARTED,
	payload: {
		job
	}
});

export const nodeCreateWizardDeployHostSucceded = (): NodeCreateWizardDeployHostSuccededAction => ({
	type: NODE_CREATE_WIZARD_ACTION.DEPLOY_HOST_SUCCEDED
});

export const nodeCreateWizardDeployHostFailed = (
	reason: string
): NodeCreateWizardDeployHostFailedAction => ({
	type: NODE_CREATE_WIZARD_ACTION.DEPLOY_HOST_FAILED,
	payload: {
		reason
	}
});

export const nodeCreateWizardDeleteHostRequested = (
	host: Host
): NodeCreateWizardDeleteHostRequestedAction => ({
	type: NODE_CREATE_WIZARD_ACTION.DELETE_HOST_REQUESTED,
	payload: {
		host
	}
});

export const nodeCreateWizardDeleteHostJobStarted = (
	job: Job
): NodeCreateWizardDeleteHostJobStartedAction => ({
	type: NODE_CREATE_WIZARD_ACTION.DELETE_HOST_JOB_STARTED,
	payload: {
		job
	}
});

export const nodeCreateWizardDeleteHostSucceded = (): NodeCreateWizardDeleteHostSuccededAction => ({
	type: NODE_CREATE_WIZARD_ACTION.DELETE_HOST_SUCCEDDED
});

export const nodeCreateWizardDeleteHostFailed = (
	reason: string
): NodeCreateWizardDeleteHostFailedAction => ({
	type: NODE_CREATE_WIZARD_ACTION.DELETE_HOST_FAILED,
	payload: {
		reason
	}
});

export const nodeCreateWizardInstallNodeRequested = (
	node: Node
): NodeCreateWizardInstallNodeRequestedAction => ({
	type: NODE_CREATE_WIZARD_ACTION.INSTALL_NODE_REQUESTED,
	payload: {
		node
	}
});

export const nodeCreateWizardInstallNodeJobStarted = (
	job: Job
): NodeCreateWizardInstallNodeJobStartedAction => ({
	type: NODE_CREATE_WIZARD_ACTION.INSTALL_NODE_JOB_STARTED,
	payload: {
		job
	}
});

export const nodeCreateWizardInstallNodeSucceded = (): NodeCreateWizardInstallNodeSuccededAction => ({
	type: NODE_CREATE_WIZARD_ACTION.INSTALL_NODE_SUCCEDED
});

export const nodeCreateWizardInstallNodeFailed = (
	reason: string
): NodeCreateWizardInstallNodeFailedAction => ({
	type: NODE_CREATE_WIZARD_ACTION.INSTALL_NODE_FAILED,
	payload: {
		reason
	}
});

export const nodeCreateWizardCreateAnotherNode = (): NodeCreateWizardCreateAnotherNodeAction => ({
	type: NODE_CREATE_WIZARD_ACTION.CREATE_ANOTHER_NODE
});
