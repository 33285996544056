export interface PreloaderState {
	isPreloadDone: boolean;
	errorMsg: string;
}

export enum PRELOADER_ACTION {
	PRELOAD_REQUESTED = "PRELOADER/PRELOAD_REQUESTED",
	PRELOAD_SUCCEDED = "PRELOADER/PRELOAD_SUCCEDED",
	PRELOAD_FAILED = "PRELOADER/PRELOAD_FAILED"
}

export interface PreloadRequestedAction {
	type: PRELOADER_ACTION.PRELOAD_REQUESTED;
}

export interface PreloadSuccededAction {
	type: PRELOADER_ACTION.PRELOAD_SUCCEDED;
}

export interface PreloadFailedAction {
	type: PRELOADER_ACTION.PRELOAD_FAILED;
	payload: {
		reason: string;
	};
}

export type PreloaderAction =
	| PreloadRequestedAction
	| PreloadSuccededAction
	| PreloadFailedAction;
