export default class UserUtils {
	static getUserRoleName = (roleId: number) => {
		switch (roleId) {
			case 3:
				return "Administrator";
			default:
				return "Unknown role";
		}
	};
}
