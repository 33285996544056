import Axios, { AxiosResponse } from "axios";
import AppEnvironment from "modules/appEnvironment";

class LogsApi {
	static async fetchHostLogList(hostName: string): Promise<AxiosResponse> {
		return await Axios.get(
			`${AppEnvironment.getApiHostAddress()}/${AppEnvironment.getApiVersion()}/hosts/${hostName}/logs`
		);
	}

	static async fetchHostLog(
		hostName: string,
		logName: string
	): Promise<AxiosResponse> {
		return await Axios.get(
			`${AppEnvironment.getApiHostAddress()}/${AppEnvironment.getApiVersion()}/hosts/${hostName}/logs/${logName}`
		);
	}
}

export default LogsApi;
