import {
	PRELOADER_ACTION,
	PreloadRequestedAction
} from "modules/preloader/types";
import { call, put, takeLatest } from "redux-saga/effects";
import ClustersApi from "modules/api/ClustersApi";
import { clusterListFetchSucceded } from "components/management/cluster/actions";
import NodesApi from "modules/api/NodesApi";
import { nodeListFetchSucceded } from "components/management/node/actions";
import HostsApi from "modules/api/HostsApi";
import { hostListFetchSucceded } from "components/management/host/actions";
import { preloadFailed, preloadSucceded } from "modules/preloader/actions";
import { userListFetchRequested } from "components/users/actions";

function* preload(action: PreloadRequestedAction) {
	try {
		const clusterListResponse = yield call(ClustersApi.fetchList);
		// console.log("saga cluster list fetched", response);
		yield put(clusterListFetchSucceded(clusterListResponse.data));

		const nodeListResponse = yield call(NodesApi.fetchList);
		// console.log("saga node list fetched", response);

		yield put(nodeListFetchSucceded(nodeListResponse.data));

		const hostListResponse = yield call(HostsApi.fetchList);
		// console.log("saga host list fetched", response);

		yield put(hostListFetchSucceded(hostListResponse.data));

		yield put(userListFetchRequested());

		yield put(preloadSucceded());
	} catch (e) {
		console.error(e);
		yield put(preloadFailed(e.message));
	}
}

function* PreloaderSideEffects() {
	yield takeLatest(PRELOADER_ACTION.PRELOAD_REQUESTED, preload);
}

export default PreloaderSideEffects;
