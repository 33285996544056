import {
	Avatar,
	Collapse,
	createStyles,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Theme,
	withStyles,
	WithStyles,
	WithTheme
} from "@material-ui/core";
import { Add, DeviceHub, ExpandLess, ExpandMore } from "@material-ui/icons";
import { AppState } from "AppState";
import SegmentItemComponent from "components/management/treeView/segmentItem/SegmentItemComponent";
import React from "react";
import { connect } from "react-redux";
import { StaticContext } from "react-router";
import { RouteComponentProps, withRouter } from "react-router-dom";
import "./ClusterItemComponent.sass";
import { Cluster } from "components/management/cluster/types";
import { nodeCreateWizardShow } from "components/management/node/nodeCreateWizard/actions";

const styles = (theme: Theme) =>
	createStyles({
		nested: {
			paddingLeft: theme.spacing(4)
		}
	});

// component local state interface
interface State {
	isExpanded: boolean;
	isSelected: boolean;
}

// PROPS
interface StateProps {
	cluster: any;
}

interface DispatchProps {
	nodeCreateWizardShow: (cluster: Cluster) => void;
}

type Props = StateProps &
	DispatchProps &
	WithStyles<typeof styles> &
	WithTheme &
	RouteComponentProps<any, StaticContext, any>;

// COMPONENT
class ClusterItemComponent extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			isExpanded: false,
			isSelected: false
		};
	}

	onItemClick = () => {
		this.props.history.push(`/management/clusters/${this.props.cluster.name}`);
		if (!this.state.isExpanded)
			this.setState(state => ({ ...state, isExpanded: true }));
	};

	onExpandClick = (event: any): void => {
		event.stopPropagation();
		event.preventDefault();
		this.setState(state => ({ ...state, isExpanded: !state.isExpanded }));
	};

	componentDidUpdate(prevProps: Props) {
		if (this.props.location !== prevProps.location) {
			this.onRouteChanged();
		}
	}

	onRouteChanged() {
		const isSelected =
			this.props.location.pathname ===
			`/management/clusters/${this.props.cluster.name}`;

		if (isSelected !== this.state.isSelected) {
			this.setState((state: State) => ({
				...state,
				isSelected
			}));
		}
	}

	render(): React.ReactNode {
		const { theme, classes } = this.props;
		const { isSelected } = this.state;

		const renderEmptyClusterItem = () => (
			<ListItem
				button
				onClick={(): void => {
					this.props.nodeCreateWizardShow(this.props.cluster);
				}}
				className={classes.nested}
			>
				<ListItemAvatar>
					<Avatar>
						<Add />
					</Avatar>
				</ListItemAvatar>
				<ListItemText
					primary="Cluster is empty"
					secondary="Click here to add first node"
				/>
			</ListItem>
		);

		const renderAddNodeItem = () => (
			<ListItem
				button
				onClick={(): void => {
					this.props.nodeCreateWizardShow(this.props.cluster);
				}}
				className={classes.nested}
			>
				<ListItemAvatar>
					<Avatar>
						<Add />
					</Avatar>
				</ListItemAvatar>
				<ListItemText primary="Add another node" />
			</ListItem>
		);

		return (
			<>
				<ListItem selected={isSelected} button onClick={this.onItemClick}>
					<ListItemAvatar>
						<Avatar
							style={
								isSelected
									? { backgroundColor: theme.palette.primary.main }
									: {}
							}
						>
							<DeviceHub />
						</Avatar>
					</ListItemAvatar>
					<ListItemText
						primary={`${this.props.cluster.name} (${
							this.props.cluster.segments.length
						}/${(this.props.cluster.nodes && this.props.cluster.nodes.length) ||
							0})`}
						secondary={this.props.cluster.nodeDefaults.dbEngine}
					/>
					{this.state.isExpanded ? (
						<ExpandLess onClick={this.onExpandClick} />
					) : (
						<ExpandMore onClick={this.onExpandClick} />
					)}
				</ListItem>
				<Collapse in={this.state.isExpanded} timeout="auto" unmountOnExit>
					{(this.props.cluster.segments.length > 0 && (
						<List disablePadding>
							{this.props.cluster.segments.map((segment: any) => {
								return (
									<SegmentItemComponent
										key={segment.segmentId}
										segment={segment}
									/>
								);
							})}
							{renderAddNodeItem()}
						</List>
					)) ||
						renderEmptyClusterItem()}
				</Collapse>
			</>
		);
	}
}

// REDUX MAPPINGS
const mapGlobalStateToProps = (state: AppState) => {
	return {};
};

const mapGlobalDispatchToProps = (dispatch: any) => {
	return {
		nodeCreateWizardShow: (cluster: Cluster) => {
			dispatch(nodeCreateWizardShow(cluster));
		}
	};
};

export default withStyles(styles, { withTheme: true })(
	withRouter(
		connect(
			mapGlobalStateToProps,
			mapGlobalDispatchToProps
		)(ClusterItemComponent)
	)
);
