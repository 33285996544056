import { Job, JobAction, JOB_ACTION } from "modules/jobs/types";

const initialState: Job[] = [];

export function jobListReducer(
	state: Job[] = initialState,
	action: JobAction
): Job[] {
	switch (action.type) {
		case JOB_ACTION.FETCH_SUCCEDED:
			return [...state, action.payload.job];
		default:
			return state;
	}
}
