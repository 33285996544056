import { Cluster } from "components/management/cluster/types";

export interface ClusterCreateWizardState {
	isOpen: boolean;
	isCreating: boolean;
	activeStep: number;
	hasErrorHappened: boolean;
	errorMessage: string;
	cluster: Cluster;
}

export enum CLUSTER_CREATE_WIZARD_ACTION {
	SHOW = "CLUSTER_CREATE_WIZARD/SHOW",
	HIDE = "CLUSTER_CREATE_WIZARD/HIDE",
	SET_CLUSTER = "CLUSTER_CREATE_WIZARD/SET_CLUSTER",
	CREATE_REQUESTED = "CLUSTER_CREATE_WIZARD/CREATE_REQUESTED",
	CREATE_SUCCEDED = "CLUSTER_CREATE_WIZARD/CREATE_SUCCEDED",
	CREATE_FAILED = "CLUSTER_CREATE_WIZARD/CREATE_FAILED"
}

export interface ClusterCreateWizardShowAction {
	type: CLUSTER_CREATE_WIZARD_ACTION.SHOW;
}

export interface ClusterCreateWizardHideAction {
	type: CLUSTER_CREATE_WIZARD_ACTION.HIDE;
}

export interface ClusterCreateWizardSetClusterAction {
	type: CLUSTER_CREATE_WIZARD_ACTION.SET_CLUSTER;
	payload: {
		cluster: Cluster;
	};
}

export interface ClusterCreateWizardCreateRequestedAction {
	type: CLUSTER_CREATE_WIZARD_ACTION.CREATE_REQUESTED;
	payload: {
		cluster: Cluster;
	};
}

export interface ClusterCreateWizardCreateSuccededAction {
	type: CLUSTER_CREATE_WIZARD_ACTION.CREATE_SUCCEDED;
}

export interface ClusterCreateWizardCreateFailedAction {
	type: CLUSTER_CREATE_WIZARD_ACTION.CREATE_FAILED;
	payload: {
		reason: string;
	};
}

export type ClusterCreateWizardAction =
	| ClusterCreateWizardShowAction
	| ClusterCreateWizardHideAction
	| ClusterCreateWizardSetClusterAction
	| ClusterCreateWizardCreateRequestedAction
	| ClusterCreateWizardCreateSuccededAction
	| ClusterCreateWizardCreateFailedAction;
