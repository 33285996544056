import React from "react";
import {
	CircularProgress,
	Grid,
	Typography,
	withStyles
} from "@material-ui/core";
import { AppState } from "../../AppState";
import { styles } from "components/root/styles";
import { connect } from "react-redux";
import { preloadRequested } from "modules/preloader/actions";

interface LocalProps {}

interface ReduxDispatchProps {
	preloadRequested: () => void;
}

type Props = LocalProps & ReduxDispatchProps;

class Preloader extends React.Component<Props> {
	constructor(props: Props) {
		super(props);

		props.preloadRequested();
	}

	render() {
		return (
			<>
				<Grid
					container
					style={{ height: "100%" }}
					direction="column"
					justify="center"
					alignItems="center"
				>
					<Grid
						item
						container
						direction="row"
						justify="center"
						alignItems="center"
						spacing={2}
					>
						<Grid item>
							<CircularProgress color="secondary" />
						</Grid>
						<Grid item>
							<Typography variant="h5">Loading...</Typography>
						</Grid>
					</Grid>
				</Grid>
			</>
		);
	}
}

// REDUX MAPPINGS
const mapGlobalStateToProps = (state: AppState) => ({});

const mapGlobalDispatchToProps = (dispatch: any) => {
	return {
		preloadRequested: () => {
			dispatch(preloadRequested());
		}
	};
};

export default withStyles(styles, { withTheme: true })(
	connect(
		mapGlobalStateToProps,
		mapGlobalDispatchToProps
	)(Preloader)
);
