export default class AppEnvironment {
	public static getApiHostAddress(): string {
		return process.env.REACT_APP_API_ADDRESS || window.location.origin;
	}

	public static getApiVersion(): string {
		return "v1";
	}

	public static getWsHostAddress(): string {
		return (
			process.env.REACT_APP_WS_ADDRESS ||
			window.location.origin.replace("http", "ws")
		);
	}
}
