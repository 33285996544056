import React from "react";
import { withStyles, WithStyles } from "@material-ui/core/styles";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	LinearProgress,
	Step,
	StepLabel,
	Stepper,
	Typography
} from "@material-ui/core";
import { styles } from "./styles";
import { connect } from "react-redux";
import { Cluster } from "components/management/cluster/types";
import { AppState } from "../../../../AppState";
import HiddenJs from "@material-ui/core/Hidden/HiddenJs";
import { NodeCreateWizardState } from "components/management/node/nodeCreateWizard/types";
import CircularProgress from "@material-ui/core/CircularProgress";
import LogTable from "components/sharedComponents/logTable/LogTableComponent";
import NodeFormComponent from "components/management/node/NodeFormComponent";
import { Host } from "components/management/host/types";
import { Node } from "components/management/node/types";
import {
	nodeCreateWizardCreateAnotherNode,
	nodeCreateWizardDeleteHostRequested,
	nodeCreateWizardDeployHostRequested,
	nodeCreateWizardGoToDataEntry,
	nodeCreateWizardGoToDeployView,
	nodeCreateWizardGoToFinish,
	nodeCreateWizardHide,
	nodeCreateWizardInstallNodeRequested,
	nodeCreateWizardSetHost,
	nodeCreateWizardSetNode
} from "components/management/node/nodeCreateWizard/actions";
import { RouteComponentProps, StaticContext, withRouter } from "react-router";
import { webSocketHandlerConnectionMessageSendRequested } from "modules/webSocketHandler/actions";

// component local state interface
interface LocalState {
	isOpen: boolean;
}

// PROPS
interface ReduxStateProps {
	cluster?: Cluster;
	nodeCreateWizard: NodeCreateWizardState;
}

interface ReduxDispatchProps {
	nodeCreateWizardSetNode: (node: Node) => void;
	nodeCreateWizardSetHost: (host: Host) => void;
	nodeCreateWizardHide: () => void;
	nodeCreateWizardGoToDataEntry: () => void;
	nodeCreateWizardGoToFinish: () => void;
	nodeCreateWizardGoToDeployView: () => void;
	nodeCreateWizardCreateAnotherNode: () => void;
	nodeCreateWizardInstallNodeRequested: (node: Node) => void;
	nodeCreateWizardDeployHostRequested: (host: Host) => void;
	webSocketHandlerConnectionMessageSendRequested: (message: any) => void;
	nodeCreateWizardDeleteHostRequested: (host: Host) => void;
}

type Props = ReduxStateProps &
	ReduxDispatchProps &
	WithStyles<typeof styles> &
	RouteComponentProps<any, StaticContext, any>;

class NodeCreateDialogComponent extends React.Component<Props, LocalState> {
	render() {
		const { classes } = this.props;
		const {
			cluster,
			node,
			host,
			isOpen,
			activeStep,
			hasErrorHappened,
			errorMessage,
			progressPercent,
			progressMessage,
			isHostDeployed,
			isHostDeletionInProgress,
			hasErrorHappenedDuringHostDeletion,
			hostDeleteErrorMessage
		} = this.props.nodeCreateWizard;

		const step1Content = (
			<>
				{/*{isHostDeployed && (*/}
				{/*	<Grid container>*/}
				{/*		<Grid item>*/}
				{/*			<Typography variant="subtitle2">*/}
				{/*				Host is successfully deployed, but node failed to install. You*/}
				{/*				can try installing node again on that host, or you can delete it*/}
				{/*				and redeploy.*/}
				{/*			</Typography>*/}
				{/*		</Grid>*/}
				{/*		<Grid item>*/}
				{/*			<Button*/}
				{/*				size="small"*/}
				{/*				disabled={isHostDeletionInProgress}*/}
				{/*				onClick={(): void => {*/}
				{/*					this.props.nodeCreateWizardDeleteHostRequested(host);*/}
				{/*				}}*/}
				{/*			>*/}
				{/*				{isHostDeletionInProgress && <CircularProgress size={20} />}*/}
				{/*				Delete host*/}
				{/*			</Button>*/}
				{/*		</Grid>*/}
				{/*	</Grid>*/}
				{/*)}*/}
				<NodeFormComponent
					cluster={cluster}
					node={node}
					host={host}
					isHostDeployed={isHostDeployed}
					isHostDeletionInProgress={isHostDeletionInProgress}
					hasErrorHappenedDuringHostDeletion={
						hasErrorHappenedDuringHostDeletion
					}
					hostDeleteErrorMessage={hostDeleteErrorMessage}
					onHostDelete={() => {
						this.props.nodeCreateWizardDeleteHostRequested(host);
					}}
					onSubmit={(node: Node, host: Host) => {
						console.log("onSubmit", node, host);

						this.props.nodeCreateWizardSetHost(host);
						this.props.nodeCreateWizardSetNode(node);

						if (this.props.nodeCreateWizard.isHostDeployed) {
							this.props.nodeCreateWizardInstallNodeRequested(node);
							this.props.nodeCreateWizardGoToDeployView();
						} else {
							this.props.nodeCreateWizardDeployHostRequested(host);
						}

						this.props.webSocketHandlerConnectionMessageSendRequested({
							type: "subscribe",
							value: `host:${this.props.nodeCreateWizard.host.name}`
						});
					}}
				/>
			</>
		);

		const step1Actions = (
			<>
				<div style={{ flexGrow: 1 }} />
				<Button
					size="small"
					disabled={isHostDeletionInProgress}
					onClick={(): void => {
						this.props.nodeCreateWizardHide();
					}}
				>
					Cancel
				</Button>
				<Button
					color="primary"
					size="small"
					type="submit"
					// make sure the form name matches the name in the NodeFormComponent
					form="nodeForm"
					disabled={isHostDeletionInProgress}
				>
					Deploy
				</Button>
			</>
		);

		const step2Content = (
			<>
				<Grid container direction="column" spacing={1}>
					<LinearProgress
						style={{ flexGrow: 1 }}
						variant="determinate"
						value={progressPercent}
					/>
					{!hasErrorHappened && (
						<Grid
							item
							container
							direction="row"
							spacing={1}
							alignItems="center"
						>
							{!this.props.nodeCreateWizard.isCreated && (
								<Grid item>
									<CircularProgress size={14} />
								</Grid>
							)}
							<Grid item>
								<Typography variant="subtitle2">{progressMessage}</Typography>
							</Grid>
						</Grid>
					)}
					<Grid item className={classes.logTable}>
						<LogTable
							rowCount={this.props.nodeCreateWizard.log.length}
							rowGetter={({ index }) => this.props.nodeCreateWizard.log[index]}
							columns={[
								{
									width: 155,
									label: "Timestamp",
									dataKey: "time"
								},
								{
									width: 0,
									flexGrow: 1,
									label: "Message",
									dataKey: "msg"
								}
							]}
						/>
					</Grid>
				</Grid>
			</>
		);

		const step2Actions = (
			<>
				<Button
					size="small"
					disabled={!this.props.nodeCreateWizard.hasErrorHappened}
					onClick={() => {
						this.props.nodeCreateWizardGoToDataEntry();
					}}
				>
					Back
				</Button>
				<div style={{ flexGrow: 1 }} />
				<Button
					color="primary"
					size="small"
					disabled={!this.props.nodeCreateWizard.isCreated}
					onClick={() => {
						this.props.nodeCreateWizardGoToFinish();
					}}
				>
					Next
				</Button>
			</>
		);

		const step3Content = (
			<>
				<Typography>Node successfully deployed.</Typography>
			</>
		);

		const step3Actions = (
			<>
				<Button
					size="small"
					onClick={() => {
						this.props.nodeCreateWizardGoToDeployView();
					}}
				>
					Back
				</Button>
				<div style={{ flexGrow: 1 }} />
				<Button
					size="small"
					onClick={() => {
						this.props.nodeCreateWizardHide();
					}}
				>
					Close
				</Button>
				<Button
					color="primary"
					size="small"
					onClick={() => {
						this.props.nodeCreateWizardCreateAnotherNode();
					}}
				>
					Create another Node
				</Button>
				<Button
					color="primary"
					size="small"
					onClick={() => {
						this.props.nodeCreateWizardHide();
						this.props.history.push(
							`/management/nodes/${this.props.nodeCreateWizard.node.name}`
						);
					}}
				>
					Show node
				</Button>
			</>
		);

		const stepper = (
			<Stepper activeStep={activeStep}>
				<Step>
					<StepLabel>{"Enter data"}</StepLabel>
				</Step>

				<Step>
					<StepLabel>{"Deploy"}</StepLabel>
				</Step>

				<Step>
					<StepLabel>{"Finish"}</StepLabel>
				</Step>
			</Stepper>
		);

		const dialogContent = (
			<>
				<DialogTitle>Add node</DialogTitle>
				<DialogContent>
					<Grid container direction="column">
						<Grid item>{stepper}</Grid>
						<Grid item>
							{hasErrorHappened && (
								<Grid
									item
									container
									direction="row"
									spacing={1}
									alignItems="center"
								>
									<Grid item>
										<Typography variant="subtitle2" color="error">
											{errorMessage}
										</Typography>
									</Grid>
								</Grid>
							)}
							{(activeStep === 0 && step1Content) ||
								(activeStep === 1 && step2Content) ||
								(activeStep === 2 && step3Content)}
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					{(activeStep === 0 && step1Actions) ||
						(activeStep === 1 && step2Actions) ||
						(activeStep === 2 && step3Actions)}
				</DialogActions>
			</>
		);

		return (
			<>
				{/*Mobile*/}
				<HiddenJs mdUp>
					<Dialog
						fullScreen={true}
						disableBackdropClick={true}
						disableEscapeKeyDown={true}
						open={isOpen}
					>
						{dialogContent}
					</Dialog>
				</HiddenJs>

				{/*Desktop*/}
				<HiddenJs smDown>
					<Dialog
						fullScreen={false}
						maxWidth="md"
						fullWidth={true}
						disableBackdropClick={true}
						disableEscapeKeyDown={true}
						open={isOpen}
					>
						{dialogContent}
					</Dialog>
				</HiddenJs>
			</>
		);
	}
}

// REDUX MAPPINGS
const mapGlobalStateToProps = (state: AppState) => ({
	nodeCreateWizard: state.nodeCreateWizard
});

const mapGlobalDispatchToProps = (dispatch: any) => ({
	nodeCreateWizardHide: () => {
		dispatch(nodeCreateWizardHide());
	},
	nodeCreateWizardGoToDataEntry: () => {
		dispatch(nodeCreateWizardGoToDataEntry());
	},
	nodeCreateWizardGoToFinish: () => {
		dispatch(nodeCreateWizardGoToFinish());
	},
	nodeCreateWizardGoToDeployView: () => {
		dispatch(nodeCreateWizardGoToDeployView());
	},
	nodeCreateWizardCreateAnotherNode: () => {
		dispatch(nodeCreateWizardCreateAnotherNode());
	},
	webSocketHandlerConnectionMessageSendRequested: (message: string) => {
		dispatch(webSocketHandlerConnectionMessageSendRequested(message));
	},
	nodeCreateWizardDeployHostRequested: (host: Host) => {
		dispatch(nodeCreateWizardDeployHostRequested(host));
	},
	nodeCreateWizardInstallNodeRequested: (node: Node) => {
		dispatch(nodeCreateWizardInstallNodeRequested(node));
	},
	nodeCreateWizardSetNode: (node: Node) => {
		dispatch(nodeCreateWizardSetNode(node));
	},
	nodeCreateWizardSetHost: (host: Host) => {
		dispatch(nodeCreateWizardSetHost(host));
	},
	nodeCreateWizardDeleteHostRequested: (host: Host) => {
		dispatch(nodeCreateWizardDeleteHostRequested(host));
	}
});

export default withStyles(styles, { withTheme: true })(
	withRouter(
		connect(
			mapGlobalStateToProps,
			mapGlobalDispatchToProps
		)(NodeCreateDialogComponent)
	)
);
